import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import slugify from 'react-slugify';

import Lottie from "lottie-react";

import create_bg from "../../assets/create_bg.webp";

import building_blocks from "../../lottie/building_blocks.json";

import { logEvent } from "firebase/analytics";

import Colors from "../../services/Colors.js";

import HStack from "../../components/utils/HStack";
import VSpacer from "../../components/utils/VSpacer";
import VStack from "../../components/utils/VStack";

import { app, auth, analytics } from "../../firebase";

import styled from "styled-components";
import "../../index.css";
import { MessageGroupType, PageType, ProfileSpaceRefType, ProfileType, SpaceMemberRefType, SpaceStationType, SpaceType } from "../../Types/HeroTypes";

import { TextField } from "@mui/material";
import {
    setDoc, Timestamp
} from "firebase/firestore";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { useAuthState } from "react-firebase-hooks/auth";
import hexToRgba from "../../components/utils/hexToRgba";

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import HSpacer from "../../components/utils/HSpacer";


const DEFAULT_SPACE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/FCMImages%2Fdefault_spacey.webp?alt=media&token=e37eecbd-6b99-430c-b073-b57622ef8440"

const db = app.firestore();

const PLACEHOLDER_SPACE_IMAGES = [
    "https://cdn.hero.page/i/c0efe4-11dc-d546-1db-ab76dca7625-samoshasfallen-an-wallpaper-of-subtle-squigly-shapes-interlocki-1c96062e-aa83-46bc-afdf-455a8f03fd44-1.webp"
]


function getRandomSpaceImage(): string {
    const randomIndex = Math.floor(Math.random() * PLACEHOLDER_SPACE_IMAGES.length);
    return PLACEHOLDER_SPACE_IMAGES[randomIndex];
}


const CreateSpaceContainer = styled(Container)`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const BodyFont = styled.p `
    text-decoration: none;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 400;
    color: ${Colors.primaryDark};
`;

const BodyFontHighlighted = styled.span `
    color: ${Colors.primaryPink};
    font-weight: 600;
`;

function CreateSpaceView({
    handleCreateSpaceClose, 
    profile, 
    station,
    isDefaultAppWorkflow,
    setIsNewSpaceAppWorkflow = null,
    template = null
}: {
  handleCreateSpaceClose: any,
  profile: ProfileType | undefined | null,
  station: SpaceStationType[],
  isDefaultAppWorkflow?: boolean,
  setIsNewSpaceAppWorkflow?: any | null,
  template?: any
}): JSX.Element {
    const [user] = useAuthState(auth);
    const navigate = useNavigate();

    const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(false);
    const [isBuildingSpace, setIsBuildingSpace] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [isAppWorkflow, setIsAppWorkflow] = useState(isDefaultAppWorkflow && isDefaultAppWorkflow);
    const [isPublic, setIsPublic] = useState<boolean>((isDefaultAppWorkflow && isDefaultAppWorkflow) || true);

    const [spaceNameQuery, setSpaceNameQuery] = useState<string>("");


    let isQueryInvalid = (
        spaceNameQuery.trim() === "" ||
        slugify(spaceNameQuery).trim() === "" ||
        slugify(spaceNameQuery.toLowerCase()).trim() === "status"
    )

    const getNewestSpaceSortIndex = () => {
        if (station.length === 0) {
            return 1000000;
        }

        return Math.max(...station.map(o => o.sortIndex !== undefined ? o.sortIndex : 0)) + 100
    }

    const isNotSignedIn = user === null || user === undefined || user.uid === null || user.uid === undefined;

    const RedirectToSignup = async () => {
        handleCreateSpaceClose();
        if (template && template.objectID) {
            navigate(`/create-account?template=${template.objectID}`);
        } else {
            navigate(`/create-account?sn=${encodeURIComponent(spaceNameQuery)}`);
        }
    }

    const CreateSpace = async () => {
        setIsSubmitDisabled(true);

        try {
            if (user !== null && user !== undefined && user.uid !== null && user.uid !== undefined) {

                setIsBuildingSpace(true);

                const newSortIndex = getNewestSpaceSortIndex();

                const SpaceRef = db.collection("Spaces").doc();
                const MessageGroupRef = db.collection("MessageGroups").doc();
                const SpacePageRef = db.collection("Spaces").doc(SpaceRef.id).collection("Pages").doc();
                const StationSpaceRef = db.collection("Profiles").doc(user.uid).collection("Station").doc(SpaceRef.id);
                const ProfileSpaceRef = db.collection("Profiles").doc(user.uid).collection("Spaces").doc(SpaceRef.id);
                const SpaceMemberProfileRef = db.collection("Spaces").doc(SpaceRef.id).collection("Members").doc(user.uid);
    
                setProgress(30);
    
                const SpaceData: SpaceType = {
                    name: spaceNameQuery,
                    img: getRandomSpaceImage(),
                    slug: slugify(spaceNameQuery),
                    sortIndex: newSortIndex,
                    createdAt: Timestamp.fromDate(new Date()),
                    ownerUID: user.uid,
                    messageGroupID: MessageGroupRef.id,
                    muted_uids: [],
                    shareable_link: "",
                    originalSpaceIDTemplate: "",
                    dateLiked: Timestamp.fromDate(new Date())
                };
    
                await setDoc(SpaceRef, SpaceData);
    
                setProgress(40);
    
                const MessageGroupData: MessageGroupType = {
                    spaceID: SpaceRef.id,
                    name: "",
                    img: "",
                    newestMessageTime: Timestamp.fromDate(new Date()),
                    newestMessage: "Send the first message!",
                    createdAt: Timestamp.fromDate(new Date()),
                    ownerUID: user.uid,
                    members: [user.uid],
                    muted_uids: [],
                    active_user_uids: [],
                    profiles_marked_as_read: [user.uid]
                };
    
                await setDoc(MessageGroupRef, MessageGroupData);
    
                setProgress(50);
    
                const shiftPalette = (palette: any[], shiftNumber: number) => {
                    const shiftIndex = shiftNumber % palette.length;
                    const shiftedPalette = palette.slice(shiftIndex).concat(palette.slice(0, shiftIndex));
                    return shiftedPalette;
                }
    
                if (template && template.template && template.template.lists) {
                    let sortIndex = 0;
                    
                    for (const list of template.template.lists) {
                        const SpacePageRef = db.collection("Spaces").doc(SpaceRef.id).collection("Pages").doc();
                        const brandPalette = shiftPalette(["#FF499E", "#D264B6", "#A480CF", "#779BE7", "#49B6FF"], sortIndex);
    
                        const PageData: PageType = {
                            name: list.list_name,
                            color: brandPalette[sortIndex % brandPalette.length],
                            sortIndex: 10000000.0 - sortIndex*10,
                            spaceID: SpaceRef.id,
                            groupID: "",
                            createdAt: Timestamp.fromDate(new Date())
                        };
            
                        await setDoc(SpacePageRef, PageData);
                        setProgress(50 + sortIndex);
    
                        sortIndex += 1;
                    }
                } else {
                    const PageData: PageType = {
                        name: !isDefaultAppWorkflow ? "Items" : "App Workflow",
                        color: "#e82f64",
                        sortIndex: 10000000.0,
                        spaceID: SpaceRef.id,
                        groupID: "",
                        createdAt: Timestamp.fromDate(new Date()),
                        workflow_type: !isDefaultAppWorkflow ? "items" : "ai_application"
                    };
                    await setDoc(SpacePageRef, PageData);
    
                    if (isDefaultAppWorkflow) {
                        const ResultsPageData: PageType = {
                            name: "Results",
                            color: "#e82f64",
                            sortIndex: 100000000.0,
                            spaceID: SpaceRef.id,
                            groupID: "",
                            createdAt: Timestamp.fromDate(new Date()),
                            workflow_type: "items"
                        };
    
                        const SpaceResultsPageRef = db.collection("Spaces").doc(SpaceRef.id).collection("Pages").doc();
                        await setDoc(SpaceResultsPageRef, ResultsPageData);
                    }
                }
    
                setProgress(60);
    
                const StationData: SpaceStationType = {
                    isWidget: false,
                    sortIndex: newSortIndex,
                    id: SpaceRef.id
                };
    
                await setDoc(StationSpaceRef, StationData);
    
                setProgress(70);
    
                const ProfileSpaceData: ProfileSpaceRefType = {
                    dateJoined: Timestamp.fromDate(new Date())
                };
    
                await setDoc(ProfileSpaceRef, ProfileSpaceData);
    
                setProgress(80);
    
                const SpaceMemberProfileData: SpaceMemberRefType = {
                    dateJoined: Timestamp.fromDate(new Date())
                };
    
                await setDoc(SpaceMemberProfileRef, SpaceMemberProfileData);

                if (isPublic && !isAppWorkflow) {
                    const SocialSpaceRef = db.collection("Profiles").doc(`${user.uid}`).collection("SocialSpaces").doc(SpaceRef.id);

                    await setDoc(SocialSpaceRef, { 
                        dateAddedToProfile: Timestamp.fromDate(new Date()),
                        slug: slugify(spaceNameQuery)
                    }, { merge: true });
                }
    
                setProgress(100);
    
                setIsBuildingSpace(false);

                logEvent(analytics, "added_space_from_invitation", {});
                
                navigate(`/dashboard/edit/${SpaceRef.id}`);
                
                setIsNewSpaceAppWorkflow(false);
                handleCreateSpaceClose();
            }
        } catch (err: any) {
            console.log(err);
            setIsBuildingSpace(false);
        }
    };

    useEffect(() => {
        if (template) {
            setSpaceNameQuery(template.template.space);
        }
    }, [template])


    return (
        isBuildingSpace ?  <BuildingSpaceLoader progress={progress} /> :
        <CreateSpaceContainer className="nogutter nopadding create-space-container" fluid>
            <Row className="nopadding">
                <Col xl={12} md={12} xs={12} className="nopadding">
                    <HStack jStart>
                        {
                            isAppWorkflow ?
                            <HomeTitleFont>
                                Choose a name for your App <HomeTitleHeavyFont>Workspace</HomeTitleHeavyFont>
                            </HomeTitleFont> :
                            <VStack>
                                <HomeTitleFont> 
                                    Choose a name for your <HomeTitleHeavyFont>Space</HomeTitleHeavyFont>
                                </HomeTitleFont>
                                <small style={{marginLeft: 10}}>
                                    In a Space, you can create endless lists and add unlimited items.
                                </small>
                            </VStack>
                        }
                    </HStack>
                </Col>

                <Col xl={12} md={12} xs={12} className="nopadding">
                    {/* <ShowOnMobile>
                        <VSpacer height={40} />
                    </ShowOnMobile> */}

                    <InputHStackContainer jStart>
                        <TextField 
                            id="new-space-name" 
                            label={spaceNameQuery.trim() === "" ? "Type something" : "You can change this later"}
                            variant="standard" 
                            value={spaceNameQuery} onChange={(e) => setSpaceNameQuery(e.target.value)}
                            style={{fontSize: 50}}
                            className="m-2 create-a-space-wrapper"
                            autoFocus
                        />
                    </InputHStackContainer>
                </Col>

                {
                    template &&
                    <Col xl={12} md={12} xs={12} className="nopadding">
                        <VStack>
                            <Alert severity="info">
                                <AlertTitle>Creating from Template</AlertTitle>
                                This Space will be created with the following lists — <strong>{template.template.lists.map((list: any) => list.list_name).join(", ")}</strong>
                            </Alert>
                            <VSpacer height={25} />
                        </VStack>
                    </Col>
                }

                <Col xl={12} md={12} xs={12} className="nopadding">
                    {
                        slugify(spaceNameQuery.trim().toLowerCase()) === "status" &&
                        <BodyFont style={{
                            textAlign: "start", 
                            color: "rgba(0,0,0,0.8)",
                            padding: 10,
                            fontWeight: 900,
                            fontSize: 14,
                            marginBottom: 20,
                            background: hexToRgba(Colors.primaryPink, 0.1),
                            borderRadius: 10
                        }}>"status" is a reserved keyword, please type something else ❣️</BodyFont> 
                    }
                    <HStack jBetween>

                        {
                            isNotSignedIn ? 
                            <HStack aCenter dynamic noGrow>
                                <CreateSpaceButton
                                    disabled={isQueryInvalid || isSubmitDisabled}
                                    onClick={() => RedirectToSignup()}
                                >
                                    Sign Up & Create Space
                                </CreateSpaceButton>

                                <HSpacer width={10} />

                                <Link to={`/sign-in`} onClick={() => handleCreateSpaceClose()} style={{color: Colors.primaryPink, textDecoration: "none"}}> or Sign in </Link>
                            </HStack> :
                            <CreateSpaceButton
                                disabled={isQueryInvalid || isSubmitDisabled}
                                onClick={() => CreateSpace()}
                            >
                                Create {isAppWorkflow ? "Works" : "S"}pace
                            </CreateSpaceButton>
                        }

                        {
                            !isAppWorkflow ?
                            <AICheck>
                                <input
                                    id="is-public" 
                                    type="checkbox" 
                                    disabled={isAppWorkflow}
                                    checked={isPublic} 
                                    onChange={(e) => setIsPublic(e.target.checked)} className="sc-gJwTLC ikxBAC" 
                                />
                                <label className="check" htmlFor="is-public">{isPublic ? "Public" : "Private"}</label>
                            </AICheck> : <></>
                        }
                    </HStack>
                </Col>
                
                {
                    !isAppWorkflow && profile ?
                    <Col xl={12} md={12} xs={12} className="nopadding">
                        <VSpacer height={40} />
                        
                        <VStack jCenter className="py-2">
                            <CreateSpaceSubtitleFont>{!isPublic ? "Only public spaces are shareable" : "This Space will be available at:"}</CreateSpaceSubtitleFont>
                            <CreateSpaceCodeFont style={isPublic ? {} : {filter: "blur(9px)"}}>hero.page/{profile.username}/<CreateSpaceCodeFontColor>{isQueryInvalid ? "your-pretty-link" : slugify(spaceNameQuery)}</CreateSpaceCodeFontColor></CreateSpaceCodeFont>
                        </VStack>
                    </Col> :
                    <Col xl={12} md={12} xs={12} className="nopadding">
                        <VSpacer height={10} />
                    </Col>
                }
            </Row>
        </CreateSpaceContainer>
    );
}


function BuildingSpaceLoader({progress}: {
    progress: number
}): JSX.Element {
    return (
       <HStack className="my-6">
            <VStack jCenter>
                <VSpacer height={50} />

                <div style={{width: 300, padding: 20}}>
                    <Lottie animationData={building_blocks} loop={true}/>
                </div>

                <HStack>
                    <VStack>
                        <BodyFont><BodyFontHighlighted>Building your Space... </BodyFontHighlighted><br /> {"(Don't close this page)"}</BodyFont>

                        <ProgressBar now={progress} label={`${progress}%`} visuallyHidden />

                        <VSpacer height={10} />
                    </VStack>
                </HStack>
            </VStack>
       </HStack>
    )
}

const ShareContainer = styled.div`
    justify-content: center;
    margin: 0 auto;
    // max-width: 650px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 10px;
    border: 0;
    box-shadow: none;
    width: 100%;

    background: url(${create_bg}) center center/cover no-repeat;

    transition: all 200ms;
`;

const ShareContainerHeader = styled.h3`
  font-size: 20px;
  margin: 0;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  color: #fff;
  white-space: nowrap;
  margin-right: 10px;
`;


const ShareContainerSubtitle = styled.span`
  font-size: 14px;
  color: #fff;
  
  font-weight: 500;
  padding-left: 10px;
  padding-right: 10px;
  margin-right: 20px;
  flex-grow: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  width: 100%;
`;

const ShareContainerCopyButton = styled.button`
  min-width: 150px;
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 38px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 14px;
  white-space: nowrap;

  &:focus {
    box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  }

  &:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(2px);
  }
`;


const InputHStackContainer = styled(HStack)`
    
`;

const ShowOnMobile = styled.div`
    display: none;

    @media screen and (max-width: 1008px) {
        display: block;
    }
`;

const CreateSpaceSubtitleFont = styled.span `
  font-size: 24px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 300;
  padding-bottom: 10px;
  text-align: start;
`;


const CreateSpaceCodeFont = styled.span `
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  padding-bottom: 10px;
  text-align: start;
`;

const CreateSpaceCodeFontColor = styled.span `
    color: ${Colors.primaryPink};
`;

const HomeTitleFont = styled.h1 `
  font-size: 24px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 300;
  padding-top: 10px;
`;

const HomeTitleHeavyFont = styled.span `
  font-weight: 500;
  color: ${Colors.primaryPink};
`;

const CreateSpaceButton = styled.button`
    align-items: center;
    appearance: none;
    background-color: #FCFCFD;
    border-radius: 24px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    font-family: "JetBrains Mono",monospace;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    margin-left: 10px;

    &:focus {
        box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
    }


    &:hover {
        box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
        transform: translateY(-2px);
    }


    &:active {
        box-shadow: #D6D6E7 0 3px 7px inset;
        transform: translateY(2px);
    }

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`;


const AICheck = styled.div`
& {
    display: flex;
    align-items: center;
    cursor:pointer;
}
& .ikxBAC {
    appearance: none;
    background-color: #dfe1e4;
    border-radius: 72px;
    border-style: none;
    flex-shrink: 0;
    height: 20px;
    margin: 0;
    position: relative;
    width: 30px;
  }

  & .ikxBAC::before {
    bottom: -6px;
    content: "";
    left: -6px;
    position: absolute;
    right: -6px;
    top: -6px;
  }

  & .ikxBAC,
  & .ikxBAC::after {
    transition: all 100ms ease-out;
  }

  & .ikxBAC::after {
    background-color: #fff;
    border-radius: 50%;
    content: "";
    height: 14px;
    left: 3px;
    position: absolute;
    top: 3px;
    width: 14px;
  }

  & input[type=checkbox] {
    cursor: default;
  }

  & .ikxBAC:hover {
    background-color: #c9cbcd;
    transition-duration: 0s;
  }

  & .ikxBAC:checked {
    background-color: ${Colors.primaryPink};
  }

  & .ikxBAC:checked::after {
    background-color: #fff;
    left: 13px;
  }

  & :focus:not(.focus-visible) {
    outline: 0;
  }

  & .ikxBAC:checked:hover {
    background-color: ${Colors.darkerPink};
  }

  & label {
    font-size: 18px;
    padding: 4px 6px;
  }
`;

export default CreateSpaceView;
