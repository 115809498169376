// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";

import firebase from 'firebase/compat/app';
import { getStorage } from "firebase/storage";
// import 'firebase/analytics';

import {
  browserLocalPersistence,
  browserSessionPersistence,
  indexedDBLocalPersistence,
  initializeAuth
} from "firebase/auth";

import { getFunctions } from "firebase/functions";

import 'firebase/compat/firestore';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAVPUj-fK5MtPCqEiO8iXBMXPatJSYtwDg",
  authDomain: "focushero-1650416072840.firebaseapp.com",
  projectId: "focushero-1650416072840",
  storageBucket: "focushero-1650416072840.appspot.com",
  messagingSenderId: "105938741747",
  appId: "1:105938741747:web:2551c53c4899513e7d8a86"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const functions = getFunctions(app);

const auth  = initializeAuth(app, {
  persistence: [
    indexedDBLocalPersistence,
    browserLocalPersistence,
    browserSessionPersistence,
  ],
});

const storage = getStorage(app);

export {
  app,
  auth,
  storage,
  analytics,
  functions
};