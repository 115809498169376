import styled from 'styled-components'


interface HStackType {
  noGrow?: boolean | null | undefined;
  dynamic?: boolean | null | undefined;
  aCenter?: boolean | null | undefined;
  center?: boolean | null | undefined;
  hide?: boolean | null | undefined;
  flexGrow?: boolean | null | undefined;
  width?: number | null | undefined;
  maxWidth?: boolean | null | undefined;
  jStart?: boolean | null | undefined;
  jFlexEnd?: boolean | null | undefined;
  jBetween?: boolean | null | undefined;
  wrap?: number | null | undefined;
  tCenter?: boolean | null | undefined;
}

const _HStack = styled.div<HStackType>;


export default _HStack`
  display: flex;
  flex-direction: row;
  justify-content: center;
  
  ${
    props => props.noGrow !== null && props.noGrow ?
    "" : "flex-grow: 1;  width: 100%;"
  }

  ${
    props => props.jFlexEnd !== null && props.jFlexEnd ?
    "justify-content: flex-end;" : ""
  }

  ${
    props => props.center !== null && props.center ?
    "justify-content: center; align-items: center;" : ""
  }

  ${
    props => props.aCenter !== null && props.aCenter ?
    "align-items: center;" : ""
  }

  ${
    props => props.jBetween !== null && props.jBetween ?
    "justify-content: space-between;" : ""
  }

  ${
    props => props.jStart !== null && props.jStart ?
    "justify-content: start;" : ""
  }

  ${
    props => props.tCenter !== null && props.tCenter ?
    "text-align: center;" : ""
  }

  ${
    props => props.wrap !== null && props.wrap === 1 ?
    "flex-wrap: wrap;" : ""
  }

  ${props => !props.dynamic ? `
        width: 100%;
        height: 100%;
    ` : ''
  }

  ${props => props.maxWidth ? `
        width: 100%;
    ` : ''
  }

  ${props => props.hide ? `
        display: none;
        pointer-events: none;
    ` : ''
  }

  ${props => props.width !== null && props.width !== undefined ? `
        width: ${props.width}px;
    ` : ''
  }

  ${props => props.flexGrow !== null && props.flexGrow !== undefined ? `
    flex-grow: 1;
  ` : ''
  }
`;
