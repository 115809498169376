import React from "react";

import {Link} from 'react-router-dom';

import styled from 'styled-components'

import Colors from "../services/Colors.js";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HStack from "../components/utils/HStack";

const MainFooterContainerStyle = styled.div`
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 50px;
    padding-bottom: 50px;
`;

const FooterHeaderFont = styled.span `
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
`;

const FooterLinkFont = styled.a `
    text-decoration: none;
    color: ${Colors.primaryDark};
    padding: 5px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;

    &:hover {
        color: ${Colors.primaryPink};
    }
`;

const FooterSpanFont = styled.span `
    text-decoration: none;
    color: ${Colors.primaryDark};
    padding: 5px;
    font-size: 16px;
    font-weight: 300;
    cursor: pointer;

    &:hover {
        color: ${Colors.primaryPink};
    }
`;


// const OpenInAppstoreAnchorStyle = styled.a`
//     background: none;
//     box-shadow: none; 
//     display: flex;
// `;

// const OpenInAppstoreImageStyle = styled.img`
//     width: 130px;
// `;

// const OpenInGooglePlaystoreImageStyle = styled.img`
//     width: 150px;
// `;

// const Divider = styled.div`
//     border-top: 1px solid rgba(0,0,0,0.2);
//     width: 100%;
//     height: 5px;
//     margin-top: 10px;
//     margin-bottom: 10px;
// `;

function MainFooterView() {
  return (
    <Container>
        <MainFooterContainerStyle>
            <Row style={{width: "100%"}}>
                <Col xl={12} md={12} xs={12} style={{padding: 10, textAlign: 'start'}}>
                    <HStack jStart>
                        <HStack aCenter wrap={1}>

                            <FooterHeaderFont>Our Socials</FooterHeaderFont>

                            <FooterLinkFont rel="noopener noreferrer" href="https://www.instagram.com/heropage_">Instagram</FooterLinkFont>
                            <FooterLinkFont rel="noopener noreferrer" href="https://twitter.com/heropage_">Twitter</FooterLinkFont>
                            <FooterLinkFont rel="noopener noreferrer" href="https://www.linkedin.com/company/hero-page">LinkedIn</FooterLinkFont>
                            {/* <FooterLinkFont rel="noopener noreferrer" href="https://www.facebook.com/hero.page.lists">Facebook</FooterLinkFont> */}
                            <FooterLinkFont rel="noopener noreferrer" href="https://www.producthunt.com/products/hero-share-discover-lists">Product Hunt</FooterLinkFont>
                            
                        </HStack>
                    </HStack>
                </Col>

                <Col xl={12} md={12} xs={12} style={{padding: 10, textAlign: 'start'}}>
                    <HStack jStart>
                        <HStack aCenter wrap={1}>

                            <FooterHeaderFont>Help</FooterHeaderFont>

                            {/* <Link to={"mailto:hello@herospace.app"} style={{ textDecoration: 'none', paddingTop: 10, paddingBottom: 10 }}>
                                <FooterSpanFont>Report a Bug or Profile</FooterSpanFont>
                            </Link> */}

                            <FooterLinkFont href="https://hero.page/podcasts">For Podcast Hosts</FooterLinkFont>
                            
                            {/* <FooterLinkFont href="https://hero.page/news">The Hero Pages: News</FooterLinkFont> */}

                            {/* <FooterLinkFont href="https://hero.page/download">Download on iOS & Android</FooterLinkFont> */}

                            <FooterLinkFont href="mailto:hello@hero.page">Report a Bug or Profile</FooterLinkFont>

                            <FooterLinkFont href="mailto:hello@hero.page">Contact Us</FooterLinkFont>

                            <FooterLinkFont href="/explore">Explore</FooterLinkFont>


                            {/* <Link to={"mailto:hello@herospace.app"} style={{ textDecoration: 'none', paddingTop: 10, paddingBottom: 10 }}>
                                <FooterSpanFont>
                                    Request Verification &nbsp;
                                    <VerifiedRoundedIcon 
                                        htmlColor={Colors.primaryPink} 
                                        style={{fontSize: 20}} 
                                    />
                                </FooterSpanFont>
                            </Link> */}
                            
                        </HStack>
                    </HStack>
                </Col>

                <Col xl={12} md={12} xs={12} style={{padding: 10, textAlign: 'start'}}>
                    <HStack jStart>
                        <HStack aCenter wrap={1}>
                            <FooterHeaderFont>Resources</FooterHeaderFont>

                            <Link to={'/blog'} style={{ textDecoration: 'none', paddingTop: 10, paddingBottom: 10 }}>
                                <FooterSpanFont>Blog</FooterSpanFont>
                            </Link>

                            <Link to={'/help/introduction-to-hero'} style={{ textDecoration: 'none', paddingTop: 10, paddingBottom: 10 }}>
                                <FooterSpanFont>List Maker Docs</FooterSpanFont>
                            </Link>

                            <Link to={'/privacy-policy'} style={{ textDecoration: 'none', paddingTop: 10, paddingBottom: 10 }}>
                                <FooterSpanFont>Privacy Policy</FooterSpanFont>
                            </Link>

                            <Link to={'/terms-and-conditions'} style={{ textDecoration: 'none', paddingTop: 10, paddingBottom: 10 }}>
                                <FooterSpanFont>Terms & Conditions</FooterSpanFont>
                            </Link>

                        </HStack>
                    </HStack>
                </Col>
            </Row>

            {/* <VStack jCenter align>
                <OpenInAppstoreAnchorStyle rel="noreferrer" href="https://apps.apple.com/au/app/hero/id1632645305" target="_blank">
                    <OpenInAppstoreImageStyle 
                        src={AppstoreButton}
                        alt="Download Hero on the App Store" 
                        width={130}
                        height={43}
                        loading="lazy"
                    />
                </OpenInAppstoreAnchorStyle>

                <OpenInAppstoreAnchorStyle rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.billbandits.hero&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1" target="_blank">
                    <OpenInGooglePlaystoreImageStyle 
                        src={GooglePlayButton} 
                        alt="Download Hero on the Google Play store" 
                        width={150}
                        height={58}
                        loading="lazy"
                    />
                </OpenInAppstoreAnchorStyle>

                <a 
                    href="https://slack.com/oauth/v2/authorize?client_id=3931496466627.4193652974864&scope=incoming-webhook,links:read,links:write,chat:write.customize,chat:write&user_scope=links:read,links:write">
                        <img 
                            alt="Add to Slack" 
                            height="40" 
                            width="139" 
                            loading="lazy"
                            src="https://platform.slack-edge.com/img/add_to_slack.png" 
                            srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" 
                        />
                </a>

            </VStack> */}

            <HStack dynamic noGrow maxWidth>
                <FooterSpanFont style={{ textDecoration: 'none', paddingTop: 10, paddingBottom: 10 }}>v5.0.0</FooterSpanFont>
            </HStack>
            
        </MainFooterContainerStyle>
      </Container>
  )
}

export default MainFooterView;