import React, { useEffect, useRef, useState, useLayoutEffect, ReactElement } from "react";
import uuid from "react-uuid";
import styled, {keyframes} from "styled-components";
import Colors from "../../services/Colors";
import { app } from "../../firebase";
import { useNavigate } from 'react-router-dom';
import { ProfileType, SpaceStationType, SpaceType, TaskType, HeroItemConfig, PageType, SpaceFeedType, OwnerFeedType } from "../../Types/HeroTypes";
import slugify from "react-slugify";

import { IconButton, Modal, CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';

import YellowCastle from "../../assets/icons/1.webp";
import PurpleHouse from "../../assets/icons/2.webp";
import PurpleFlower from "../../assets/icons/3.webp";
import PurpleDoor from "../../assets/icons/4.webp";
import OrangePhoto from "../../assets/icons/6.webp";

import AddCircleIcon from '@mui/icons-material/AddCircle';
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';

import HSpacer from "../../components/utils/HSpacer";
import HStack from "../../components/utils/HStack";
import VStack from "../../components/utils/VStack";
import VSpacer from "../../components/utils/VSpacer";

import { analytics } from "../../firebase";
import { logEvent } from "firebase/analytics";

import Tooltip from '@mui/material/Tooltip';
import Box from "@mui/material/Box"

import PostSpaceSelector from "./PostSpaceSelector";

import { Timestamp } from "firebase/firestore";

import { toast } from "react-toastify";

const ManyItemsModalStyle = {
    position: 'fixed' as 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "400px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: "200px",
    overflow: "hidden",
    borderRadius: 1,
    zIndex: 21474836499992929929292799,
    outline: 0
};


const MiniCreator = React.lazy(() => import("../../views/App/MiniItemCreator"));

const db = app.firestore();

function truncate(str: string, n: number){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

const IMAGE_CONFIG: HeroItemConfig = {
    type: 'image',
    imageUrl: 'https://cdn.hero.page/i/a852065-6a6a-814d-a1e8-382ce1702-samoshasfallen-a-gradient-color-background-of-purple-and-blue-a-1636458c-4ea6-4f3f-924e-8406ca849d03-1.png',
    copy: 'Upload an Image',
    key_id: "1"
};

const GIF_CONFIG: HeroItemConfig = {
    type: 'gif',
    imageUrl: 'https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/task_pictures%2Ffda67e5-63fa-58d8-5daf-fbeada0f541-8aaa48-346a-8360-3612-c63d22a8af1?alt=media&token=7ff607a2-59e5-4920-b461-a799f11ab273',
    copy: 'Upload a GIF',
    key_id: "2"
};

const LINK_CONFIG: HeroItemConfig = {
    type: 'link',
    imageUrl: 'https://cdn.hero.page/i/c0efe4-11dc-d546-1db-ab76dca7625-samoshasfallen-an-wallpaper-of-subtle-squigly-shapes-interlocki-1c96062e-aa83-46bc-afdf-455a8f03fd44-1.webp',
    copy: 'Paste a Link to see a Preview',
    key_id: "3"
};

const TEXT_CONFIG: HeroItemConfig = {
    type: 'text',
    imageUrl: 'https://cdn.hero.page/i/c0efe4-11dc-d546-1db-ab76dca7625-samoshasfallen-an-wallpaper-of-subtle-squigly-shapes-interlocki-1c96062e-aa83-46bc-afdf-455a8f03fd44-1.webp',
    copy: 'Paste a Link to see a Preview',
    key_id: "4"
};

const PROMPT_CONFIG: HeroItemConfig = {
    type: 'prompt',
    imageUrl: 'https://cdn.hero.page/i/c0efe4-11dc-d546-1db-ab76dca7625-samoshasfallen-an-wallpaper-of-subtle-squigly-shapes-interlocki-1c96062e-aa83-46bc-afdf-455a8f03fd44-1.webp',
    copy: 'Paste a Link to see a Preview',
    key_id: "5"
};

const validateItems = (
    items: TaskType[],
    itemConfigs: HeroItemConfig[],
    setPostDisabledReasons: (reasons: string[]) => void // Add this parameter to update the state
  ) => {
    const errorMessages: string[] = []; // Create an array to hold error messages
    
    // Validate each item
    for (let index = 0; index < items.length; index++) {
      const item = items[index];
      const configType = itemConfigs[index].type;

      if (!item.title || typeof item.title !== 'string' || item.title.trim() === '') {
        errorMessages.push('Please provide a title for your post.');
      }
      switch (configType) {
        case 'gif':
        case 'image':
          if (!item.img || typeof item.img !== 'string' || item.img.trim() === '') {
            errorMessages.push('Please upload an image.');
          }
          break;
        case 'link':
          if (!item.url || typeof item.url !== 'string' || item.url.trim() === '') {
            errorMessages.push('Please provide a valid URL.');
          }
          break;
        case 'text':
        case 'prompt':
          if (!item.note || typeof item.note !== 'string' || item.note.trim() === '') {
            errorMessages.push(`Please provide a non-empty ${configType === "text" ? "note" : "prompt"}.`);
          }
          if (configType === 'prompt' && (!item.isAIPrompt || typeof item.isAIPrompt !== 'boolean')) {
            errorMessages.push('Prompt type must have AI enabled.');
          }
          break;
        default:
          errorMessages.push('Invalid configuration type.');
      }
    }
  
    if (errorMessages.length > 0) {
      setPostDisabledReasons(errorMessages); // Update the state with the error messages
      throw new Error('Validation failed');
    }
};

const validateAndUpdatePostBundle = (
    postBundle: {
      space: SpaceType;
      page: PageType;
      items: TaskType[];
    },
    itemConfigs: HeroItemConfig[],
    setPostDisabledReasons: (reasons: string[]) => void, // Add this parameter to update the state,
    skipCheck?: boolean
  ) => {
    // Update spaceID and pageID for each item
    postBundle.items = postBundle.items.map((item) => ({
      ...item,
      spaceID: postBundle.space.id || '',
      pageID: postBundle.page.id || '',
    }));
  
    // Call the separate validation function
    if (!skipCheck) {
        validateItems(postBundle.items, itemConfigs, setPostDisabledReasons);
    }
  
    return postBundle;
};


function replaceSpaceWithNewImage(img: string) {
    const OLD_DEFAULT_SPACE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/space_pictures%2Fdefault_space_picture.png?alt=media&token=86ebcd39-b198-47f9-a8ec-a7250e9a28ec";
    const DEFAULT_SPACE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/FCMImages%2Fdefault_spacey.webp?alt=media&token=e37eecbd-6b99-430c-b073-b57622ef8440";
  
    if (img === OLD_DEFAULT_SPACE_IMG) {
        return DEFAULT_SPACE_IMG
    } else {
        return img
    }
}

function getSortedSpace(spaces: SpaceType[], station: SpaceStationType[]): SpaceType[] {
    let sorted_spaces: SpaceType[] = [...spaces];
  
    sorted_spaces = sorted_spaces.sort((a: SpaceType, b: SpaceType) => {
      if (a.id !== null && a.id !== undefined && b.id !== null && b.id !== undefined) {
          let aI = station.findIndex((s) => s.id === a.id);
          let bI = station.findIndex((s) => s.id === b.id);
  
          if (aI === -1 || bI === -1) {
              return 0
          } else {
              return station[aI].sortIndex - station[bI].sortIndex;
          }
      } else {
          return 0;
      }
  });

  return sorted_spaces;
}

function ItemAdderContainer({
    profile,
    setSelectedItem,
    selectedItem,
    miniSelector = false,
    spaces,
    station,
    setProfile,
    setIsCreateSpaceOpen,
    StoreFrontLeftColRef
}: {
    profile: ProfileType | null | undefined,
    setSelectedItem: any,
    miniSelector?: boolean,
    spaces: SpaceType[],
    selectedItem: TaskType | null,
    station: SpaceStationType[],
    setProfile: any,
    setIsCreateSpaceOpen: any,
    StoreFrontLeftColRef: any
}): JSX.Element {
    const [selectedSpace, setSelectedSpace] = useState<SpaceType | null>(null);

    const [itemPosted, setItemPosted] = useState(false);

    const [configs, setConfigs] = useState<HeroItemConfig[]>([Object.assign({}, IMAGE_CONFIG, {key_id: uuid()})]); // useState<HeroItemConfig[]>([]); //
    const scrollRef = useRef<HTMLDivElement>(null);

    const [initialScrollTop, setInitialScrollTop] = useState(0);

    const [isUploadingItems, setIsUploadingItems] = useState(false);
    
    const CONFIG_MAP: Record<string, HeroItemConfig> = {
        'image': IMAGE_CONFIG,
        'gif': GIF_CONFIG,
        'link': LINK_CONFIG,
        'text': TEXT_CONFIG,
        'prompt': PROMPT_CONFIG
    };

    const prevConfigsLength = useRef(configs.length); // Store the initial length
    const configItemsSerialized = JSON.stringify(
        configs.map(config => config.item).filter(Boolean)
    );  

    const [isPostEnabled, setIsPostEnabled] = useState(false);
    const [postDisabledReasons, setPostDisabledReasons] = useState<string[]>([]);
    const [configBucketSchema, setConfigBucketSchema] = useState<any | null>(null);

    const ANIMATION_TIME = 7;

      
    const updateConfig = (type: string, replaceAll: boolean = false) => {
        if (StoreFrontLeftColRef.current) {
          setInitialScrollTop(StoreFrontLeftColRef.current.scrollTop);
        }
      
        const newConfig = Object.assign({}, CONFIG_MAP[type], {key_id: uuid()}) || {}; // Fallback to an empty object if the type is not defined
      
        if (replaceAll) {
          setConfigs([newConfig]);
        } else {
          setConfigs(prevConfigs => [...prevConfigs, newConfig]); // Use function form to ensure you always have the most up-to-date state
        }
    };

    useEffect(() => {
        if (spaces.length > 0 && selectedSpace === null) {
            setSelectedSpace(getSortedSpace(spaces, station)[0]);
        }
    }, [spaces, station, selectedSpace])

    const deleteConfig = (id: string) => {
        if (StoreFrontLeftColRef.current) {
          // Capture current scroll position
          const currentScrollTop = StoreFrontLeftColRef.current.scrollTop;
          setInitialScrollTop(currentScrollTop);
        }
      
        const newConfigs = configs.filter(config => config.key_id !== id);
        setConfigs(newConfigs);
    };      
    useLayoutEffect(() => {
        if (configs.length !== prevConfigsLength.current) {
            if (StoreFrontLeftColRef.current && scrollRef.current) {
                const elementPosition = scrollRef.current.getBoundingClientRect().top;
                const containerPosition = StoreFrontLeftColRef.current.getBoundingClientRect().top;
                const offsetPosition = elementPosition - containerPosition;
    
                // Set the scrollTop position with smooth behavior
                StoreFrontLeftColRef.current.scrollTo({
                    top: initialScrollTop + offsetPosition - 41,
                    behavior: 'smooth'
                });
    
                prevConfigsLength.current = configs.length;
            }
        }
    }, [configs]);

    useEffect(() => {
          try {
            validateItems(configs.map(config => config.item).filter(Boolean) as TaskType[], configs, setPostDisabledReasons);
            setIsPostEnabled(true);
            setPostDisabledReasons([]); // hide if not full
          } catch (err) {
            setIsPostEnabled(false);
          }
    }, [configItemsSerialized]);

    const selectedItemRef = useRef<TaskType | null>(null);

    useEffect(() => {
        selectedItemRef.current = selectedItem;
    }, [selectedItem]);

    const handleConfigBucket = async (configBucket: { space: SpaceType; page: PageType }) => {
        setConfigBucketSchema(configBucket);
        setIsUploadingItems(true);

        try {

            if (profile && profile.uid && profile.username && configBucket.space.id)  {
                const postBundle: {
                    space: SpaceType;
                    page: PageType;
                } & {
                    items: (TaskType | undefined)[];
                } = {
                    space: configBucket.space,
                    page: configBucket.page,
                    items: selectedItem !== null ? [selectedItem] : configs.map((config) => config.item),
                };
            
                const filteredPostBundle = {
                  ...postBundle,
                  items: postBundle.items.filter((item): item is TaskType => item !== undefined),
                };

                // use selectedItem if  not null
            
                const skipCheck = selectedItem !== null;

                if (selectedItem) {
                    selectedItemRef.current = selectedItem;
                }

                const validatedPostBundle = validateAndUpdatePostBundle(filteredPostBundle, configs, setPostDisabledReasons, skipCheck);
      
                const submitted_by = {
                  uid: profile.uid,
                  username: profile.username,
                  img: profile.img
                };

                let owner_object = {};

                if (configBucket.space.ownerUID !== profile.uid) {
                    const OwnerProfileRef = (await db.collection("Profiles").doc(`${configBucket.space.ownerUID}`).get()).data();

                    if (OwnerProfileRef) {
                        owner_object = {
                            uid: configBucket.space.ownerUID,
                            username: OwnerProfileRef.username,
                            img: OwnerProfileRef.img
                        }
                    }
                }

                const spaceFeed: SpaceFeedType = {
                    id: configBucket.space.id,
                    name: configBucket.space.name,
                    owner: configBucket.space.ownerUID === profile.uid ? submitted_by : (owner_object as OwnerFeedType),
                    img: configBucket.space.img,
                    slug: configBucket.space.slug || slugify(configBucket.space.name.toLowerCase())
                };

                validatedPostBundle.items = validatedPostBundle.items.map((item) => ({
                    ...item,
                    space: spaceFeed,
                    submitted_by,
                    createdAt: Timestamp.fromDate(new Date()),
                    owner_uid: profile.uid
                }));

                for (const newItem of validatedPostBundle.items) {
                    const TasksRef = db.collection("Spaces").doc(`${configBucket.space.id}`).collection("Tasks").doc();

                    if (selectedItem !== null) {
                        const TaskCommentRef = db.collection("Comments").doc();

                        // TODO: Make sure only get not null fields from selectedItem
                        if (selectedItem.spaceID || (selectedItem.space && selectedItem.space.id)) {
                            const CommentData = Object.assign({}, selectedItem, {
                                isReply: true,
                                createdAt: Timestamp.fromDate(new Date()),
                                owner_uid: profile.uid,
                                submitted_by: {
                                    img: profile.img,
                                    uid: profile.uid,
                                    username: profile.username
                                },
                                send_approval_email: false,
                                is_bookmarked: true,

                                itemRepliedTo: selectedItem,
                    
                                itemID: selectedItem.id,
                                spaceID:  (selectedItem.space && selectedItem.space.id) ? selectedItem.space.id : selectedItem.spaceID || "",
                                note: "_reposted this item_"
                            });
            
                            await TaskCommentRef.set(CommentData);
                        }
                    }
                    await TasksRef.set(newItem);
                }

                setItemPosted(true);

                if (selectedItem === null) {
                    logEvent(analytics, "created_post_from_mini_creator", {});
                } else {
                    logEvent(analytics, "added_item_from_quick_button", {});
                }

                if (StoreFrontLeftColRef !== null) {
                    updateConfig("image", true);
                }

                setIsUploadingItems(false);

                if (selectedItem !== null) {
                    const PingRef = db.collection("Profiles").doc(`${profile.uid}`).collection("PingRequests").doc();

                    const Ping = {
                        profileOfReceiver: selectedItem.owner_uid,
                        profileOfNotifier: profile.uid,
                        message: `has added your item "${selectedItem.title}" to one of their spaces!`
                    }

                    await PingRef.set(Ping);
                }

                toast(
                    <VStack maxWidth style={{minWidth: 250}}>
                            <HStack aCenter dynamic maxWidth>
                                <ModalText>
                                    🎉 Item added! You can either open your Space, or keep exploring!
                                </ModalText>
                            </HStack>

                            <VSpacer height={10} />

                            <SpaceItemOpener 
                                onClick={() => navigate(`/dashboard/edit/${configBucket.space.id}/${configBucket.page.id}`)}
                                space={configBucket.space}
                            />

                            <VSpacer height={10} />
                    </VStack>, 
                    { autoClose: 5000, className: "toastify-this-brah" }
                );

                setSelectedItem(null);
            
                setTimeout(() => {
                    setConfigBucketSchema(null);
                }, ANIMATION_TIME * 1000);
            
            }
        } catch (err) {
          console.log(err);
        }
    };

    const setIsCreatePostOpen = () => {};

    const PostChildComponent = (
        <div>
            <PostSpaceSelector
                setConfigs={setConfigs}
                itemPosted={itemPosted}
                setProfile={setProfile} 
                isPostEnabled={isPostEnabled}
                profile={profile}
                spaces={spaces}
                station={station}
                selectedSpace={selectedSpace}
                setSelectedSpace={setSelectedSpace}
                forMiniCreator={true}
                miniSelector={miniSelector}
                setConfigBucket={handleConfigBucket}
            />
        </div>
    );

    const navigate = useNavigate();

    const [isUploadingMultiple, setIsUploadingMultiple] = useState(false);

    return (
        !miniSelector ?
        <VStack maxWidth>
            {
                isUploadingMultiple &&
                <Modal
                    open={isUploadingMultiple}
                    onClose={() => setIsUploadingMultiple(false)}
                    aria-labelledby="many-items-modal-title"
                    style={{zIndex: 99999999999999999999999999999999999}}
                    aria-describedby="many-items-description"
                >
                    <Box sx={ManyItemsModalStyle}>
                        <VStack maxWidth jCenter style={{height: "100%", alignItems: "center", justifyContent: "center"}}>
                            <CircularProgress style={{color: Colors.primaryPink}} />
                            <VSpacer height={20} />
                            <p>Uploading all images...</p>
                        </VStack>
                    </Box>
                </Modal>
            }
            <ItemToolbar
                updateConfig={updateConfig} //updateConfig
                configs={configs}
            />
            <ItemAdder
                deleteConfig={deleteConfig}
                setIsCreatePostOpen={setIsCreatePostOpen}
                profile={profile}
                setIsUploadingMultiple={setIsUploadingMultiple}
                setSelectedItem={setSelectedItem}
                spaces={spaces}
                station={station}
                setProfile={setProfile}
                configs={configs}
                setConfigs={setConfigs}
                scrollRef={scrollRef}
                StoreFrontLeftColRef={StoreFrontLeftColRef}
                isUploadingItems={isUploadingItems}
                postDisabledReasons={postDisabledReasons}
                configBucketSchema={configBucketSchema}
                setConfigBucketSchema={setConfigBucketSchema}
                ANIMATION_TIME={ANIMATION_TIME}
                updateConfig={updateConfig}
                PostChildComponent={PostChildComponent}
            />
        </VStack> :
        PostChildComponent
    );
}


type ItemAdderProps = {
    profile: ProfileType | null | undefined,
    setSelectedItem: any,
    spaces: SpaceType[],
    station: SpaceStationType[],
    setProfile: any,
    configs: HeroItemConfig[],
    setConfigs: React.Dispatch<React.SetStateAction<HeroItemConfig[]>>,
    scrollRef: React.RefObject<HTMLDivElement>,
    StoreFrontLeftColRef: React.RefObject<HTMLDivElement>,
    isUploadingItems: boolean,
    postDisabledReasons: string[],
    configBucketSchema: any | null,
    setConfigBucketSchema: React.Dispatch<React.SetStateAction<any | null>>,
    ANIMATION_TIME: number,
    updateConfig: (type: string, isSingle?: boolean) => void,
    setIsCreatePostOpen: (open: boolean) => void,  // Newly added
    deleteConfig: (key_id: string) => void,  // Newly added
    setIsUploadingMultiple: any,
    PostChildComponent: ReactElement,  // Changed from ReactNode
};

const ItemAdder: React.FC<ItemAdderProps> = ({
    profile,
    setSelectedItem,
    spaces,
    station,
    setProfile,
    configs,
    setIsUploadingMultiple,
    setConfigs,
    scrollRef,
    StoreFrontLeftColRef,
    isUploadingItems,
    postDisabledReasons,
    configBucketSchema,
    setConfigBucketSchema,
    ANIMATION_TIME,
    updateConfig,
    setIsCreatePostOpen,  // Newly added
    deleteConfig,  // Newly added
    PostChildComponent
}) => {
    const navigate = useNavigate();

    return (
        <>
            <div>
                {configs.map((config, index) => (
                    <MiniCreatorWrapper ref={configs.length - 1 === index ? scrollRef : null} key={`${index}-${config.key_id}`} style={isUploadingItems ? {opacity: 0.6, pointerEvents: "none"} : {}}>
                        {
                            (index !== 0 || configs.length > 1) && config.key_id &&
                            <IconButton
                                className="x-button-wrapper"
                                onClick={() => {
                                    if (config.key_id) {
                                        deleteConfig(config.key_id)
                                    }
                                }}
                                style={{margin: 10, zIndex: 999}}
                            >
                                <CloseIcon style={{ color: 'white' }} />
                            </IconButton>
                        }
                        {
                            (configs.length > 1) &&
                            <div
                                className="number-badge-wrapper"
                                onClick={() => {}}
                            >
                                <span>{index + 1}</span>
                            </div>
                        }
                        <MiniCreator
                            index={index}
                            profile={profile}
                            setShowItemEditor={setIsCreatePostOpen}
                            setSelectedItem={setSelectedItem}
                            config={config}
                            setIsUploadingMultiple={setIsUploadingMultiple}
                            configs={configs}
                            setConfigs={setConfigs}
                            isLast={index === configs.length - 1}
                            partOfMultiple={configs.length > 1}
                            spaceSelector={index === configs.length - 1 ? (
                                <HStack maxWidth jStart aCenter>
                                    <div style={{position: "relative"}}>
                                        <MoreItemsAdder>
                                            <>
                                                <ReusableFunButton 
                                                    onClick={() => updateConfig('image')} 
                                                    src={OrangePhoto} 
                                                    label="Add an Image Post" 
                                                    tooltipContent={"Add an image to your existing post"} 
                                                />
                                                <ReusableFunButton 
                                                    onClick={() => updateConfig('gif')} 
                                                    src={PurpleHouse} 
                                                    label="Add a GIF Post" 
                                                    tooltipContent={"Add a GIF to your existing post"} 
                                                />
                                                <ReusableFunButton 
                                                    onClick={() => updateConfig('link')} 
                                                    src={YellowCastle} 
                                                    label="Add a Link Post" 
                                                    tooltipContent={"Add a link to your existing post"} 
                                                />
                                                <ReusableFunButton 
                                                    onClick={() => updateConfig('text')} 
                                                    src={PurpleFlower} 
                                                    label="Add a Text Post" 
                                                    tooltipContent={"Add text to your existing post"} 
                                                />
                                                {/* <ReusableFunButton 
                                                    onClick={() => updateConfig('prompt')} 
                                                    src={PurpleDoor} 
                                                    label="Add an AI Prompt" 
                                                    tooltipContent={"Add an AI prompt to your existing post"} 
                                                /> */}
                                            </>
                                        </MoreItemsAdder>
                                    </div>

                                    <HSpacer width={15} />
                                    {
                                            postDisabledReasons.length === 0 ? (
                                            PostChildComponent
                                            ) : 
                                            profile ? (
                                            <Tooltip
                                                title={
                                                <React.Fragment>
                                                    {postDisabledReasons.map((reason, index) => (
                                                    <div key={index} style={{fontSize: 14}}>{`${index + 1}. ${reason}`}</div>
                                                    ))}
                                                </React.Fragment>
                                                }
                                                arrow
                                            >
                                                {PostChildComponent}
                                            </Tooltip>
                                        ) :
                                        (
                                            PostChildComponent
                                        )
                                    }
                                    <HSpacer width={10} />
                                </HStack>
                            ) : <></>}
                        />
                    </MiniCreatorWrapper>
                ))}
            </div>
        </>
    );
};

type ItemToolbarProps = {
    updateConfig: (type: string, condition: boolean) => void,
    configs: Array<{ type: string }>,
};
  
const ItemToolbar: React.FC<ItemToolbarProps> = ({ updateConfig, configs }) => {
    return (
        <HStack aCenter maxWidth dynamic className="toolbar-sticky" style={{background: "white"}}>
            <ReusableFunButton 
                onClick={() => updateConfig('image', configs.length === 1)} 
                src={OrangePhoto} 
                label="Image" 
                horizontal 
                tooltipContent={configs.length === 1 ? "Post an image instead" : "Add an image to your post"} 
                isActive={configs.length === 1 && configs[0].type === 'image'}
            />
            <ReusableFunButton 
                onClick={() => updateConfig('gif', configs.length === 1)} 
                src={PurpleHouse} 
                label="GIF" 
                horizontal 
                tooltipContent={configs.length === 1 ? "Post a GIF instead" : "Add a GIF to your post"} 
                isActive={configs.length === 1 && configs[0].type === 'gif'}
            />
            <ReusableFunButton 
                onClick={() => updateConfig('link', configs.length === 1)} 
                src={YellowCastle} 
                label="Link" 
                horizontal 
                tooltipContent={configs.length === 1 ? "Post a link instead" : "Add a link to your post"} 
                isActive={configs.length === 1 && configs[0].type === 'link'}
            />
            <ReusableFunButton 
                onClick={() => updateConfig('text', configs.length === 1)} 
                src={PurpleFlower} 
                label="Text" 
                horizontal 
                tooltipContent={configs.length === 1 ? "Post text instead" : "Add text to your post"} 
                isActive={configs.length === 1 && configs[0].type === 'text'}
            />
            {/* <ReusableFunButton 
                onClick={() => updateConfig('prompt', configs.length === 1)} 
                src={PurpleDoor} 
                label="AI Prompt" 
                horizontal 
                tooltipContent={configs.length === 1 ? "Post an AI prompt instead" : "Add an AI prompt to your post"} 
                isActive={configs.length === 1 && configs[0].type === 'prompt'}
            /> */}
        </HStack>
    )
}

interface ReusableFunButtonProps {
    onClick?: () => void;
    src: string;
    label: string;
    horizontal?: boolean;
    tooltipContent?: string;
    isActive?: boolean; // new prop for active state
};
  
const ReusableFunButton: React.FC<ReusableFunButtonProps> = ({ onClick, src, label, horizontal, tooltipContent, isActive = false }) => {
    return (
      tooltipContent && !isActive ? (
        <Tooltip 
          title={<div style={{fontSize: 14}}>{tooltipContent}</div>} 
          placement={horizontal ? "bottom" : "left"}
          arrow={horizontal}
          componentsProps={{
            tooltip: {
              sx: {
                bgcolor: 'common.black',
                '& .MuiTooltip-arrow': {
                  color: 'common.black',
                },
              },
            },
          }}
        >
          {renderButton(onClick, src, label, horizontal, isActive, tooltipContent)}
        </Tooltip>
      ) : (
        renderButton(onClick, src, label, horizontal, isActive, label)
      )
    );
};
  
const renderButton = (onClick: (() => void) | undefined, src: string, label: string, horizontal: boolean | undefined, isActive: boolean, tooltipContent: string) => {
    return (
      horizontal ? 
      <FunButtonHorizontal aria-label={tooltipContent} onClick={onClick || (() => {})} horizontal={horizontal} style={isActive ? {background: "rgba(0,0,0,0.06)"} : {}}>
          <HStack aCenter dynamic maxWidth>
              <span>
                  <FunButtonIconHorizontal src={src} alt={`${label} icon`} />
                  <span className="small-label">{label}</span>
              </span>
          </HStack>
      </FunButtonHorizontal> :
      <FunButton aria-label={tooltipContent} onClick={onClick || (() => {})} horizontal={horizontal}>
          <HStack aCenter dynamic maxWidth jBetween>
              <span className="dropdown-wrapper">
                  <FunButtonIcon src={src}  alt={`${label} icon`} />
                  <span className="dropdown-label">{label}</span>
              </span>
              <HSpacer width={10} />
              <AddCircleIcon style={{ fontSize: 24, color: "#171717", marginRight: 10 }} />
          </HStack>
      </FunButton>
    );
}
  
const FunButton = styled.button<{ horizontal?: boolean }>`
    & {
        align-items: center;
        background-color: #fff;
        border-radius: 40px;
        box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
        box-sizing: border-box;
        color: #121212;
        cursor: pointer;
        display: inherit;
        font-family: Inter,sans-serif;
        font-size: 15px;
        font-weight: 700;
        justify-content: start;
        line-height: 1;
        outline: none;
        padding: 0px 12px;
        text-align: start;
        text-decoration: none;
        transition: all .2s,-webkit-box-shadow .2s;
        border: 0;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        align-items: center !important;
        width: auto !important;  // Add this line
        flex-direction: ${props => (props.horizontal ? "row" : "column")};
        margin: 2px 0;
    }

    & svg {
        opacity: 0.2;
    }

    & .dropdown-label {
        white-space: nowrap;
    }

    & .dropdown-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &:hover {
        box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;

        svg {
            opacity: 1;
        }
    }
`;

const FunButtonIcon = styled.img<{ horizontal?: boolean }>`
  width: ${props => (props.horizontal ? "30px" : "35px")};
  height: ${props => (props.horizontal ? "30px" : "35px")};
  margin-right: 10px;
`;

interface AnimatedBorderProps {
    duration: number;
}
  
const slideIn = keyframes`
    from {
      width: 0%;
    }
    to {
      width: 100%;
    }
`;
  
const AnimatedBorder = styled.div<AnimatedBorderProps>`
    height: 4px;
    background-color: #FF69B4; // Your primary pink color
    animation: ${slideIn} ${(props) => props.duration}s linear;
`;
  
const AnimatedBorderComponent: React.FC<AnimatedBorderProps> = ({ duration }) => {
    return <AnimatedBorder duration={duration} />;
};

const BottomRightVStack = styled(VStack)`
    position: fixed;
    z-index: 9999999999;
    background: white;
    bottom: 20px;
    right: 20px;
    box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
    box-sizing: border-box;
    max-width: 400px;
    border-radius: 20px;
    overflow: hidden;
`;

function SpaceItemOpener({space, onClick}: {
    space: SpaceType,
    onClick: any
}): JSX.Element {
    return (
        <SpaceItemPickerWrapper 
            forMiniCreator={true}
            onClick={onClick}
            style={{ opacity: 1 , textDecoration: 'none' }}
        >
            <HStack aCenter maxWidth jStart>
                <SpaceImage space={space} size={30} />
                <SpaceNameWrapper>
                    Open "{truncate(space.name, 10)}" in Space Editor
                </SpaceNameWrapper>
            </HStack>

            <HSpacer width={10} />
            <ArrowCircleRightRoundedIcon />
        </SpaceItemPickerWrapper>
    )
}

function SpaceImage({space, size}: {
    space: any;
    size: number;
  }): JSX.Element {
    const [hasError, setHasError] = useState(false)

    return (
      space !== null ? 
      <SpaceImageWrapper
        src={hasError ? "https://cdn.hero.page/i/c0efe4-11dc-d546-1db-ab76dca7625-samoshasfallen-an-wallpaper-of-subtle-squigly-shapes-interlocki-1c96062e-aa83-46bc-afdf-455a8f03fd44-1.webp" : replaceSpaceWithNewImage(space.img)} 
        id={replaceSpaceWithNewImage(space.img)}
        onError={() => setHasError(true)}
        alt={`${space.name} on Hero`}
        size={size}
      /> : 
      <></>
    )
}

interface SpaceItemPickerWrapperProps {
    forMiniCreator?: boolean;
  }
  
const SpaceItemPickerWrapper = styled.button<SpaceItemPickerWrapperProps>`
    /* Conditional styles based on forMiniCreator */
    ${props => props.forMiniCreator ? `
        align-items: center;
        background-color: #fff;
        border-radius: 40px;
        box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
        box-sizing: border-box;
        color: #121212;
        cursor: pointer;
        display: inherit;
        font-family: Inter,sans-serif;
        font-size: 18px;
        font-weight: 900 !important;
        justify-content: start;
        line-height: 1;
        margin: 10px 0;
        outline: none;
        padding: 12px 20px;
        text-align: start;
        text-decoration: none;
        transition: all .2s,-webkit-box-shadow .2s;
        border: 0;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        display: flex;
        align-items: center !important;
        width: auto !important;  // Add this line

        & > svg {
            font-size: 24px;
            // margin-right: 10px;
        }

        &:hover {
            box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
    
            // svg {
            //     opacity: 1;
            // }
        }
    ` :
    `
        padding: 10px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;
        justify-content: space-between;
        align-items: center;
        display: flex;
        outline: none;
        border: 1px solid rgba(0,0,0,0.08);
        background: 0;
        box-shadow: 0;
    
        &:hover {
            background-color: rgba(0,0,0,0.05);
            opacity: 1 !important;
        }`
    }
`;

interface SpaceImageWrapperType {
    size?: number | null | undefined;
}

const _SpaceImageWrapper = styled.img<SpaceImageWrapperType>;
  
const SpaceImageWrapper = _SpaceImageWrapper`
  object-fit: cover;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 100%;
  background-color: white;
  margin-right: 5px;
`;

const SpaceNameWrapper = styled.span`
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  text-decoration: none;
  text-align: left;
  color: ${Colors.primaryDark};
  box-decoration-break: clone;
  float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
    padding-top: 2px;
    padding-bottom: 2px;
`;

const MenuTitle = styled.span`
    font-size: 16px;
    font-weight: 700;
    color: ${Colors.primaryDark};
`;

const UnstyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0,0,0,0.5);
  margin-bottom: 25px;
`;

const ModalText = styled.span`
    font-weight: 500;
    font-size: 14px;

    max-width: 400px;
    padding: 5px 0;
`;


interface MoreItemsAdderProps {
    children: React.ReactNode;
}

const DropdownHoverPlus = styled(AddCircleOutlineRoundedIcon)<{shown: number}>`
    cursor: pointer;
    border-radius: 50%;

    ${props => props.shown ? 'background: black;color: white;' : ''};

    &:hover {
        background: black;
        color: white;
    }
`;
  

const CustomHoverBox = styled(Box)`
  position: relative;

  &:hover .dropdown {
    visibility: visible;
    opacity: 1;
  }
`;
  
const Dropdown = styled(Box)<{shown: number}>`
  position: absolute;
  padding-top: 20px;
  left: -260px;
  z-index: 10000;
  visibility: ${props => props.shown ? 'visible' : 'hidden'};
  opacity: ${props => props.shown ? '1' : '0'};
  transition: visibility 0s, opacity 0.5s linear;
  top: -100px;
`;

interface MoreItemsAdderProps {
  children: React.ReactNode;
}

const MoreItemsAdder: React.FC<MoreItemsAdderProps> = ({ children }) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <CustomHoverBox
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <DropdownHoverPlus style={{ fontSize: 35 }} shown={isShown ? 1 : 0}/>
      <Dropdown className="dropdown" shown={isShown ? 1 : 0}>
        <VStack>
          {children}
        </VStack>
      </Dropdown>
    </CustomHoverBox>
  );
};

const MiniCreatorWrapper = styled.div`
    position: relative;

    & > .MuiIconButton-root.x-button-wrapper {
        position: absolute;
        visibility: hidden;
        right: 2px; 
        top: 1px; 
        z-index: 10000; 
        background: ${Colors.primaryDark};
    }

    & > .number-badge-wrapper {
        position: absolute;
        visibility: visible;
        left: 0px; 
        top: 0px; 
        padding: 2px 4px;
        border-bottom-right-radius: 4px;
        // background: ${Colors.primaryDark};
        background: rgba(0,0,0,0.3);
        z-index: 10; 

        & > span {
            font-size: 12px;
            font-weight: 900;
            color: white;
        }
    }

    &:hover > .MuiIconButton-root.x-button-wrapper  {
        visibility: visible;
    }
`;


const FunButtonHorizontal = styled.button<{ horizontal?: boolean }>`
    & {
        align-items: center;
        background-color: #fff;
        box-sizing: border-box;
        color: #121212;
        cursor: pointer;
        display: inherit;
        font-family: Inter,sans-serif;
        font-size: 18px;
        font-weight: 400;
        justify-content: center;
        line-height: 1;
        // margin: 10px 0;
        flex-grow: 1;
        outline: none;
        padding: 8px 12px;
        text-align: center;
        text-decoration: none;
        transition: all .2s,-webkit-box-shadow .2s;
        border: 0;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        // padding-left: 26px;
        align-items: center !important;
        width: auto !important;  // Add this line
        flex-direction: ${props => (props.horizontal ? "row" : "column")};
    }

    & svg {
        opacity: 0.2;
    }

    & .small-label {
        font-weight: 700;
        font-size: 14px;

        opacity: 0.7;
    }

    &:hover {
        // box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
        background: rgba(0,0,0,0.06);
        svg {
            opacity: 1;
        }

        & .small-label {
            font-weight: 700;
            font-size: 14px;
    
            opacity: 1;
        }
    }
`;

const FunButtonIconHorizontal = styled.img<{ horizontal?: boolean }>`
  width: 25px;
  height: 25px;
  margin-right: 5px;
`;

export default ItemAdderContainer;