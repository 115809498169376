import styled from 'styled-components';

interface HSpacerType {
  width?: number | null | undefined;
}

const _HSpacer = styled.div<HSpacerType>;

export default _HSpacer`
  ${props => props.width !== null ? `
        width: ${props.width}px;
    ` : ''
  }
`;
