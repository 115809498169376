import styled from "styled-components";

interface VStackType {
  jCenter?: boolean | null | undefined;
  jStart?: boolean | null | undefined;
  align?: boolean | null | undefined;
  width?: number | null | undefined;
  spaceBetween?: boolean | null | undefined;
  maxWidth?: boolean | null | undefined;
}

const _VStack = styled.div<VStackType>;

export default _VStack`
  display: flex;
  flex-direction: column;

  ${props => props.jCenter !== null && props.jCenter ? 'justify-content: center;' : ''}
  ${props => props.jCenter !== null && props.jCenter ? 'justify-content: start;' : ''}
  ${props => props.spaceBetween !== null && props.spaceBetween ? 'justify-content: space-between; height: 100%;' : ''}
  ${props => props.align !== null && props.align ? 'align-items: center;' : ''}

  ${props => props.width !== null && props.width !== undefined ? `
        width: ${props.width}px;
    ` : ''
  }

  ${props => props.maxWidth !== null && props.maxWidth !== undefined && props.maxWidth ? "width: 100%;" : "" }
`;

