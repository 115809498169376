import React, {useState, useEffect} from "react";
import {Link, useNavigate, useSearchParams} from 'react-router-dom';
import styled from 'styled-components'

import Colors from "../../services/Colors.js";


import HStack from "../../components/utils/HStack";
import VStack from "../../components/utils/VStack";
import VSpacer from "../../components/utils/VSpacer";

import Lottie from "lottie-react";
import progress_loader from "../../lottie/progress_loader.json"

import "../../index.css"

import MainFooter from "../MainFooter";

import PrimaryButton from "../../components/utils/PrimaryButton";

import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import { logEvent } from "firebase/analytics";

import { auth, analytics } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";

import {
    signInWithEmailAndPassword,
  } from "firebase/auth";

const SignInContainer = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  padding: 25px;
  background: #fff;
  margin: 0 auto;
  text-align: center;
`;

const SignInTitleFont = styled.h1 `
  font-size: 42px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 300;
  text-align: center;

  @media screen and (max-width: 908px) {
    max-width: 400px;
  }
`;

const SignInTitleHeavyFont = styled.span `
  font-weight: 500;
  color: ${Colors.primaryPink};
`;

const SignInInlineLinkFont = styled.p `
    text-decoration: none;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 400;
    color: ${Colors.primaryDark};
`;

const SignInInlineLinkFontColor = styled.span `
    color: ${Colors.primaryPink};
    font-weight: 600;
`;

function SignInView({
    mini = false
}: {
    mini?: boolean
}): JSX.Element {
    const [email, setEmail] = useState("");
    const [authError, setAuthError] = useState<string | null>(null);
    const [password, setPassword] = useState("");
    const [showActionLoader, setShowActionLoader] = useState(false);
    const [user, loading] = useAuthState(auth);
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    // E.g. `/dashboard?page=2&order=asc`
    const e_email = searchParams.get("e_email");
    const e_pass = searchParams.get("e_pass");
    const redirect = searchParams.get("redirect");


    useEffect(() => {
        setShowActionLoader(loading);
        if (user) {
            const params = new URLSearchParams(window.location.search);
            params.delete("redirect");
            const queryString = params.toString();
    
            if (redirect === "auth:zapier") {
                navigate(`/auth?c=zapier&${queryString}`);
            } else {
                if (!mini) {
                    navigate("/spaces");
                }
            }
        }
    }, [user, loading, redirect]);

    useEffect(() => {
        if (e_email && e_pass) {
            setEmail(e_email);
            setPassword(e_pass);
        }
    }, [e_email, e_pass])

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function isPasswordValid(password: string) {
        return password.length > 7
    }

    const logInWithEmailAndPassword = async (email: string, password: string) => {
        setShowActionLoader(true);
        setAuthError(null);

        try {
            await signInWithEmailAndPassword(auth, email, password);

            logEvent(analytics, "sign_in", {});
        } catch (err: any) {

            switch(err.code) {
                case "auth/invalid-email": {
                    setAuthError("The email you used is invalid.");
                    break;
                }
                case "auth/wrong-password": {
                    setAuthError("The email or password is incorrect.");
                    break;
                }
                case "auth/network-request-failed": {
                    setAuthError("Hm... We couldn't connect to the internet. Please check your connection, and try again.");
                    break;
                }
            }

            setPassword("");

            setShowActionLoader(false);
        }
      };    

    return (
        mini ?
        <VStack maxWidth>
            <Col sm={12} className="my-3">
                <Form.Label htmlFor="inlineFormInputEmail" visuallyHidden>
                    Email
                </Form.Label>
                <Form.Control 
                    id="inlineFormInputEmail" 
                    placeholder="Email" 
                    className="hero-form-control-wrapper" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Col>

            <Col sm={12} className="my-3">
                <Form.Label htmlFor="inlineFormInputPassword" visuallyHidden>
                    Password
                </Form.Label>
                <Form.Control 
                    id="inlineFormInputPassword" 
                    type="password"
                    placeholder="Password"  
                    className="hero-form-control-wrapper"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Col>


            {
                authError !== null && authError !== undefined ?
                <><br /><SignInInlineLinkFont>{authError}</SignInInlineLinkFont></> : null
            }

            <Col className="my-3">
                {
                    showActionLoader ?
                    <HStack dynamic noGrow>
                        <div style={{width: 200}}>
                            <Lottie animationData={progress_loader} loop={true}/>
                        </div>
                    </HStack>
                    :
                    <PrimaryButton 
                        color={Colors.primaryPink} 
                        onClick={() => logInWithEmailAndPassword(email, password)}
                        maxWidth
                        disabled={!isValidEmail(email) || !isPasswordValid(password)}
                    >
                        Sign In
                    </PrimaryButton>
                }
            </Col>
        </VStack> :
        <SignInContainer>
            <Container style={{maxWidth: 600}}>
                <VSpacer height={50} />
                
                <HStack>
                    <VStack jCenter>
                        <SignInTitleFont>Sign In to <SignInTitleHeavyFont>Hero</SignInTitleHeavyFont></SignInTitleFont>
                        <br />
                    </VStack>
                </HStack>

                <Col className="justify-content-center">
                    <Col sm={12} className="my-3">
                        <Form.Label htmlFor="inlineFormInputEmail" visuallyHidden>
                            Email
                        </Form.Label>
                        <Form.Control 
                            id="inlineFormInputEmail" 
                            placeholder="Email" 
                            className="hero-form-control-wrapper" 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Col>

                    <Col sm={12} className="my-3">
                        <Form.Label htmlFor="inlineFormInputPassword" visuallyHidden>
                            Password
                        </Form.Label>
                        <Form.Control 
                            id="inlineFormInputPassword" 
                            type="password"
                            placeholder="Password"  
                            className="hero-form-control-wrapper"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </Col>

                    <Link to={'/create-account'} style={{textDecoration: "none"}}>
                        <SignInInlineLinkFont>Don't have an account? <SignInInlineLinkFontColor>Create one for free!</SignInInlineLinkFontColor></SignInInlineLinkFont>
                    </Link>

                    <Link to={'/forgot-password'} style={{textDecoration: "none"}}>
                        <SignInInlineLinkFont>Forgot your password?</SignInInlineLinkFont>
                    </Link>

                    {
                        authError !== null && authError !== undefined ?
                        <><br /><SignInInlineLinkFont>{authError}</SignInInlineLinkFont></> : null

                    }

                    <Col className="my-3">
                        {
                            showActionLoader ?
                            <HStack dynamic noGrow>
                                <div style={{width: 200}}>
                                    <Lottie animationData={progress_loader} loop={true}/>
                                </div>
                            </HStack>
                            :
                            <PrimaryButton 
                                color={Colors.primaryPink} 
                                onClick={() => logInWithEmailAndPassword(email, password)}
                                maxWidth
                                disabled={!isValidEmail(email) || !isPasswordValid(password)}
                            >
                                Sign In
                            </PrimaryButton>
                        }
                    </Col>
                </Col>

            </Container>

            <VSpacer height={40} />
            <MainFooter />
        </SignInContainer>
    );
}

export default SignInView;