import React, {useState, useEffect} from "react";
import {Link, useNavigate, useLocation} from 'react-router-dom';
import styled from 'styled-components'

import Colors from "../../services/Colors.js";

import HStack from "../../components/utils/HStack";
import VStack from "../../components/utils/VStack";
import VSpacer from "../../components/utils/VSpacer";

import ProgressBar from 'react-bootstrap/ProgressBar';

import Lottie from "lottie-react";
import progress_loader from "../../lottie/progress_loader.json"
import building_blocks from "../../lottie/building_blocks.json"

import MainFooter from "../MainFooter";

import "../../index.css"

import PrimaryButton from "../../components/utils/PrimaryButton";

import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';

import { logEvent } from "firebase/analytics";

import { auth, app, analytics } from "../../firebase";
import { setDoc, Timestamp } from "firebase/firestore"; 
import { useAuthState } from "react-firebase-hooks/auth";

import Alert from '@mui/material/Alert';

import {
    createUserWithEmailAndPassword,
} from "firebase/auth";

import { getDoc } from "firebase/firestore";
import { MessageGroupType, PageType, ProfileSpaceRefType, ProfileType, SpaceMemberRefType, SpaceStationType, SpaceType, UsernameType } from "../../Types/HeroTypes.js";
import Helper from "../../services/Helper";
import { Row } from "react-bootstrap";
import slugify from "react-slugify";

import TemplatePreview from "../Marketplace/SpaceTemplatePreview";

const BodyFont = styled.p `
    text-decoration: none;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 400;
    color: ${Colors.primaryDark};
`;

const BodyFontHighlighted = styled.span `
    color: ${Colors.primaryPink};
    font-weight: 600;
`;

const db = app.firestore();

const DEFAULT_PROFILE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/FCMImages%2Fdp.webp?alt=media&token=e6cc2ec8-b097-4166-9277-29a68a2afd74"
const DEFAULT_SPACE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/space_pictures%2Fdefault_space_picture.png?alt=media&token=86ebcd39-b198-47f9-a8ec-a7250e9a28ec"

const CreateAccountContainer = styled.div`
  display: flex;
  justify-content: center; 
  align-items: center;
  flex-direction: column;
  padding: 25px;
  background: #fff;
  margin: 0 auto;
  text-align: center;
`;

const CreateAccountTitleFont = styled.h1 `
  font-size: 42px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 300;
  text-align: center;

  @media screen and (max-width: 908px) {
    max-width: 400px;
  }
`;

const CreateAccountTitleHeavyFont = styled.span `
  font-weight: 500;
  color: ${Colors.primaryPink};
`;

const CreateAccountSubtitleFont = styled.p `
  font-size: 24px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 300;
  padding-bottom: 10px;
  text-align: center;
`;

const CreateAccountCodeFont = styled.p `
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
  font-weight: 600;
  padding-bottom: 10px;
  text-align: center;
`;

const CreateAccountCodeFontColor = styled.span `
    color: ${Colors.primaryPink};
`;

const CreateAccountInlineLinkFont = styled.p `
    text-decoration: none;
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 400;
    color: ${Colors.primaryDark};
`;

const CreateAccountInlineLinkFontColor = styled.span `
    color: ${Colors.primaryPink};
    font-weight: 600;
`;

const PLACEHOLDER_SPACE_IMAGES = [
    "https://cdn.hero.page/i/c0efe4-11dc-d546-1db-ab76dca7625-samoshasfallen-an-wallpaper-of-subtle-squigly-shapes-interlocki-1c96062e-aa83-46bc-afdf-455a8f03fd44-1.webp"
]

function getRandomSpaceImage(): string {
    const randomIndex = Math.floor(Math.random() * PLACEHOLDER_SPACE_IMAGES.length);
    return PLACEHOLDER_SPACE_IMAGES[randomIndex];
}


function CreateAccountView({setProfile, mini = false}: {
    setProfile: any,
    mini?: boolean
}) {
    const [authError, setAuthError] = useState<string | null>(null);
    const [progress, setProgress] = useState<number>(0);
    const [isBuildingProfile, setIsBuildingProfile] = useState<boolean>(false);

    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [showActionLoader, setShowActionLoader] = useState<boolean>(false);

    const [user, loading, error] = useAuthState(auth);
    
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const template = queryParams.get('template');
    const existingSpaceName = decodeURIComponent(queryParams.get('sn') || "");

    const [spaceTemplate, setSpaceTemplate] = useState<any>(null)
    const [spaceLists, setSpaceLists] = useState<any>([]);

    const navigate = useNavigate();

    function isValidEmail(email: string) {
        return /\S+@\S+\.\S+/.test(email);
    }

    function isPasswordValid(password: string, confirmPassword: string) {
        return password.length > 7 && password === confirmPassword
    }

    function isUsernameValid(username: string) {
        return /^\s*([0-9a-zA-Z]+)\s*$/.test(username) && username.length > 2
    }

    // console.log(spaceTemplate);
    // console.log(spaceLists);

    const checkIfCredentialsValid = async (username: string, email: string, password: string) => {
        setShowActionLoader(true);
        setAuthError(null);

        try {
            if (isUsernameValid(username)) {
                const usernameRef = db.collection("Usernames").doc(username);

                const doc = await getDoc(usernameRef);

                if (doc.exists()) {
                    setAuthError("This username is taken. Choose another and try again.");

                    setShowActionLoader(false);
                } else {
                    registerWithEmailAndPassword(username, email, password)
                }
            } else {
                setAuthError("This username is invalid, please try another.");

                setShowActionLoader(false);
            }
        } catch (err: any) {
            switch(err.code) {
                case "auth/network-request-failed": {
                    setAuthError(err.message);
                    break;
                }
                default: setAuthError("Something went wrong, please try again.");
            }

            setShowActionLoader(false);
        }
    }

    useEffect(() => {
        const fetchTemplate = async (id: string) => {
            try {
                if (id.trim() !== "") {
                    const TemplateRef = db.collection("SpaceTemplates").doc(id);
    
                    const doc = await getDoc(TemplateRef);
    
                    if (doc.exists()) {
                        const TemplateData = doc.data();

                        // const BlogRef = db.collection("TemplateBlogpostSummaries").doc(TemplateData.blog_link_info.post_id);

                        // const blog_doc = await BlogRef.get();

                        // const BlogData = blog_doc.data();

                        setSpaceTemplate(TemplateData);
                        setSpaceLists(TemplateData.template);
                    }
                }
            } catch (err: any) {
                console.log(err);
            }
        }

        if (template) {
            fetchTemplate(template);
        } else {
            setSpaceLists([]);
            setSpaceTemplate(null);
        }
    }, [template]);

    const registerWithEmailAndPassword = async (username: string, email: string, password: string) => {
        try {
            const res = await createUserWithEmailAndPassword(auth, email, password);

            const user = res.user;

            setIsBuildingProfile(true);
            setShowActionLoader(false);

            // Setting Up Profile

            const UsernameRef = db.collection("Usernames").doc(username);
            const ProfileRef = db.collection("Profiles").doc(user.uid);

            const SpaceRef = db.collection("Spaces").doc();
            const MessageGroupRef = db.collection("MessageGroups").doc();
            const SpacePageRef = db.collection("Spaces").doc(SpaceRef.id).collection("Pages").doc();
            const StationSpaceRef = db.collection("Profiles").doc(user.uid).collection("Station").doc(SpaceRef.id);
            const ProfileSpaceRef = db.collection("Profiles").doc(user.uid).collection("Spaces").doc(SpaceRef.id);
            const SpaceMemberProfileRef = db.collection("Spaces").doc(SpaceRef.id).collection("Members").doc(user.uid);

            const UsernameData: UsernameType = {
                uid: user.uid,
                dateRegistered: Timestamp.fromDate(new Date())
            };

            setProgress(10);

            await setDoc(UsernameRef, UsernameData);

            setProgress(20);

            const ProfileData: ProfileType = {
                username: username,
                device_id: [],
                img: DEFAULT_PROFILE_IMG,
                isDeleted: false,
                hasInitialisedTags: false,
                isGridViewSelected: false,
                isAddButtonHintHidden: false,
                isTaskTypeHintHidden: false,
                newSpacesBadgeNumber: 0,
                savedSpacesBadgeNumber: 0,
                bio: ""
            };

            await setDoc(ProfileRef, ProfileData);

            setProgress(30);

            const SpaceData: SpaceType = {
                name: spaceLists && spaceLists.space ? spaceLists.space : existingSpaceName.trim() || "Personal",
                img: getRandomSpaceImage(),
                slug: spaceLists && spaceLists.space ? slugify(spaceLists.space.toLowerCase()) : existingSpaceName.trim() !== "" ? slugify(existingSpaceName.trim().toLowerCase()) :  "personal",
                sortIndex: 10000000.0,
                createdAt: Timestamp.fromDate(new Date()),
                ownerUID: user.uid,
                messageGroupID: MessageGroupRef.id,
                muted_uids: [],
                shareable_link: "",
                originalSpaceIDTemplate: "",
                dateLiked: Timestamp.fromDate(new Date())
            };

            await setDoc(SpaceRef, SpaceData);

            setProgress(40);

            const MessageGroupData: MessageGroupType = {
                spaceID: SpaceRef.id,
                name: "",
                img: "",
                newestMessageTime: Timestamp.fromDate(new Date()),
                newestMessage: "Send the first message!",
                createdAt: Timestamp.fromDate(new Date()),
                ownerUID: user.uid,
                members: [user.uid],
                muted_uids: [],
                active_user_uids: [],
                profiles_marked_as_read: [user.uid]
            };

            await setDoc(MessageGroupRef, MessageGroupData);

            setProgress(50);

            const shiftPalette = (palette: any[], shiftNumber: number) => {
                const shiftIndex = shiftNumber % palette.length;
                const shiftedPalette = palette.slice(shiftIndex).concat(palette.slice(0, shiftIndex));
                return shiftedPalette;
            }

            if (spaceLists && spaceLists.lists) {
                let sortIndex = 0;
                
                for (const list of spaceLists.lists) {
                    const SpacePageRef = db.collection("Spaces").doc(SpaceRef.id).collection("Pages").doc();
                    const brandPalette = shiftPalette(["#FF499E", "#D264B6", "#A480CF", "#779BE7", "#49B6FF"], sortIndex);

                    const PageData: PageType = {
                        name: list.list_name,
                        color: brandPalette[sortIndex % brandPalette.length],
                        sortIndex: 10000000.0 - sortIndex*10,
                        spaceID: SpaceRef.id,
                        groupID: "",
                        createdAt: Timestamp.fromDate(new Date())
                    };
        
                    await setDoc(SpacePageRef, PageData);
                    setProgress(50 + sortIndex);

                    sortIndex += 1;
                }
            } else {
                const PageData: PageType = {
                    name: "Items",
                    color: "#e82f64",
                    sortIndex: 10000000.0,
                    spaceID: SpaceRef.id,
                    groupID: "",
                    createdAt: Timestamp.fromDate(new Date())
                };
    
                await setDoc(SpacePageRef, PageData);
            }


            setProgress(60);

            const StationData: SpaceStationType = {
                isWidget: false,
                sortIndex: 1000000,
                id: SpaceRef.id
            };

            await setDoc(StationSpaceRef, StationData);

            setProgress(70);

            const ProfileSpaceData: ProfileSpaceRefType = {
                dateJoined: Timestamp.fromDate(new Date())
            };

            await setDoc(ProfileSpaceRef, ProfileSpaceData);

            setProgress(80);

            const SpaceMemberProfileData: SpaceMemberRefType = {
                dateJoined: Timestamp.fromDate(new Date())
            };

            await setDoc(SpaceMemberProfileRef, SpaceMemberProfileData);

            const newProfile = await getDoc(ProfileRef);

            const SocialSpaceRef = db.collection("Profiles").doc(`${user.uid}`).collection("SocialSpaces").doc(SpaceRef.id);

            await setDoc(SocialSpaceRef, { 
                dateAddedToProfile: Timestamp.fromDate(new Date()),
                slug: "personal"
            }, { merge: true });

            if (newProfile.exists()) {
                setProfile(Helper.ProfileDocToObject(newProfile))
            }

            setProgress(100);

            setIsBuildingProfile(false);

            logEvent(analytics, "sign_up", {});

            if (!mini) {
                // navigate("/spaces");
                navigate(`/dashboard/edit/${SpaceRef.id}`);
            } else {
                logEvent(analytics, "sign_up_from_mini_creator", {});
            }

        } catch (err: any) {
            switch(err.code) {
                case "auth/email-already-in-use": {
                    setAuthError("An account with this email already exists.");
                    break;
                }
                case "auth/invalid-email": {
                    setAuthError("The email you used is invalid.");
                    break;
                }
                case "auth/wrong-password": {
                    setAuthError("The email or password is incorrect.");
                    break;
                }
                case "auth/network-request-failed": {
                    setAuthError(err.message);
                    break;
                }
            }

            setShowActionLoader(false);

            console.log(err);
        }
    };

    useEffect(() => {
        if (user && !isBuildingProfile && !mini) navigate("/spaces");
      }, [user, isBuildingProfile]);

    const isTemplatePage = (
        spaceTemplate !== undefined && spaceLists !== undefined &&
        spaceTemplate !== null && spaceLists !== null
    );

    return (
        isBuildingProfile ? <BuildingProfileLoader mini={mini} progress={progress} /> :
        mini ?
        <VStack maxWidth>
            <Col sm={12} className="my-3">
                <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                    Username
                </Form.Label>
                <InputGroup>
                    <InputGroup.Text>&nbsp;@</InputGroup.Text>
                    <Form.Control
                        id="inlineFormInputGroupUsername"
                        placeholder="username"
                        className="no-left-border hero-form-control-wrapper"
                        value={username}
                        onChange={(e) => setUsername(e.target.value.toLowerCase().trim())}
                    />
                </InputGroup>
            </Col>

            {
                !/^\s*([0-9a-zA-Z]+)\s*$/.test(username) && username.length > 0 ?
                <CreateAccountInlineLinkFont>Usernames can only contain letters and numbers.</CreateAccountInlineLinkFont> : null

            }

            <Col sm={12} className="my-3">
                <Form.Label htmlFor="inlineFormInputEmail" visuallyHidden>
                    Email
                </Form.Label>
                <Form.Control 
                    id="inlineFormInputEmail" 
                    placeholder="Email" 
                    className="hero-form-control-wrapper" 
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Col>

            <Col sm={12} className="my-3">
                <Form.Label htmlFor="inlineFormInputPassword" visuallyHidden>
                    Password
                </Form.Label>
                <Form.Control 
                    id="inlineFormInputPassword" 
                    type="password"
                    placeholder="Password"  
                    className="hero-form-control-wrapper"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </Col>

            <Col sm={12} className="my-3">
                <Form.Label htmlFor="inlineFormInputConfirmPassword" visuallyHidden>
                    Confirm Password
                </Form.Label>
                <Form.Control 
                    id="inlineFormInputConfirmPassword" 
                    type="password" 
                    placeholder="Confirm Password"  
                    className="hero-form-control-wrapper"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
            </Col>

            {
                authError !== null && authError !== undefined ?
                <><br /><CreateAccountInlineLinkFont>{authError}</CreateAccountInlineLinkFont></> : null

            }

            <Col className="my-3">
                {
                    showActionLoader ?
                    <HStack dynamic noGrow>
                        <div style={{width: 200}}>
                            <Lottie animationData={progress_loader} loop={true}/>
                        </div>
                    </HStack>
                    :
                    <PrimaryButton 
                        color={Colors.primaryPink} 
                        maxWidth
                        onClick={() => {
                            if (!isPasswordValid(password, confirmPassword)) {
                                if (password.length < 8) {
                                    setAuthError("Choose a password that's at least 8 characters.")
                                } else if (password !== confirmPassword) {
                                    setAuthError("Passwords don't match. Please try again.")
                                }
                            } else {
                                checkIfCredentialsValid(username, email, password);
                            }
                        }}
                        disabled={!isPasswordValid(password, confirmPassword) || !isUsernameValid(username) || !isValidEmail(email)}
                    >
                        Sign Up
                    </PrimaryButton>
                }
            </Col>
        </VStack> :
        <CreateAccountContainer>
            <Container style={{maxWidth: !isTemplatePage ? 600 : "90%"}}>
                <VSpacer height={50} />
                <HStack>
                    <VStack jCenter>
                        <CreateAccountTitleFont>Create a <CreateAccountTitleHeavyFont>free</CreateAccountTitleHeavyFont> Hero account</CreateAccountTitleFont>
                        <br />
                        <CreateAccountSubtitleFont>Your Public Spaces will be available at:</CreateAccountSubtitleFont>
                        <CreateAccountCodeFont>hero.page/<CreateAccountCodeFontColor>{username.trim() !== "" ? username : "username"}</CreateAccountCodeFontColor></CreateAccountCodeFont>
                        {
                            existingSpaceName.trim() &&
                            <>
                                <br />
                                <Alert severity="success"> Once you sign up, "{existingSpaceName}" will be created as your first space!</Alert>
                                <br />
                            </>
                        }
                    </VStack>
                </HStack>
                
                    <Row>
                        <Col sm={12} md={isTemplatePage ? 5 : 12} className="justify-content-center">
                            <Col sm={12} className="my-3">
                                <Form.Label htmlFor="inlineFormInputGroupUsername" visuallyHidden>
                                    Username
                                </Form.Label>
                                <InputGroup>
                                    <InputGroup.Text>&nbsp;@</InputGroup.Text>
                                    <Form.Control
                                        id="inlineFormInputGroupUsername"
                                        placeholder="username"
                                        className="no-left-border hero-form-control-wrapper"
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value.toLowerCase().trim())}
                                    />
                                </InputGroup>
                            </Col>

                            {
                                !/^\s*([0-9a-zA-Z]+)\s*$/.test(username) && username.length > 0 ?
                                <CreateAccountInlineLinkFont>Usernames can only contain letters and numbers.</CreateAccountInlineLinkFont> : null

                            }

                            <Col sm={12} className="my-3">
                                <Form.Label htmlFor="inlineFormInputEmail" visuallyHidden>
                                    Email
                                </Form.Label>
                                <Form.Control 
                                    id="inlineFormInputEmail" 
                                    placeholder="Email" 
                                    className="hero-form-control-wrapper" 
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Col>

                            <Col sm={12} className="my-3">
                                <Form.Label htmlFor="inlineFormInputPassword" visuallyHidden>
                                    Password
                                </Form.Label>
                                <Form.Control 
                                    id="inlineFormInputPassword" 
                                    type="password"
                                    placeholder="Password"  
                                    className="hero-form-control-wrapper"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Col>

                            <Col sm={12} className="my-3">
                                <Form.Label htmlFor="inlineFormInputConfirmPassword" visuallyHidden>
                                    Confirm Password
                                </Form.Label>
                                <Form.Control 
                                    id="inlineFormInputConfirmPassword" 
                                    type="password" 
                                    placeholder="Confirm Password"  
                                    className="hero-form-control-wrapper"
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                            </Col>

                            <Link to={'/sign-in'} style={{textDecoration: "none"}}>
                                <CreateAccountInlineLinkFont>Already have an account? <CreateAccountInlineLinkFontColor>Sign in</CreateAccountInlineLinkFontColor></CreateAccountInlineLinkFont>
                            </Link>


                            {
                                authError !== null && authError !== undefined ?
                                <><br /><CreateAccountInlineLinkFont>{authError}</CreateAccountInlineLinkFont></> : null

                            }

                                <Col className="my-3">
                                    {
                                        showActionLoader ?
                                        <HStack dynamic noGrow>
                                            <div style={{width: 200}}>
                                                <Lottie animationData={progress_loader} loop={true}/>
                                            </div>
                                        </HStack>
                                        :
                                        <PrimaryButton 
                                            color={Colors.primaryPink} 
                                            maxWidth
                                            onClick={() => {
                                                if (!isPasswordValid(password, confirmPassword)) {
                                                    if (password.length < 8) {
                                                        setAuthError("Choose a password that's at least 8 characters.")
                                                    } else if (password !== confirmPassword) {
                                                        setAuthError("Passwords don't match. Please try again.")
                                                    }
                                                } else {
                                                    checkIfCredentialsValid(username, email, password);
                                                }
                                            }}
                                            disabled={!isPasswordValid(password, confirmPassword) || !isUsernameValid(username) || !isValidEmail(email)}
                                        >
                                            Sign Up
                                        </PrimaryButton>
                                    }
                                </Col>
                            </Col>
                            <Col sm={12} md={isTemplatePage ? 7 : 12} className="my-3" style={{padding: 20, paddingTop: 0}}>
                                {
                                    spaceTemplate && spaceLists &&
                                    <VStack>
                                        <HStack jStart aCenter maxWidth dynamic noGrow>
                                            <CreateAccountSubtitleFont>
                                                Start building your {spaceTemplate.schema.type_of_space} Space!
                                            </CreateAccountSubtitleFont>
                                        </HStack>
                                        {/* <BlogSplashImage src={spaceTemplate.image} alt={`Feature image of example of a Space with lists ${spaceLists.lists.map((list: any) => list.list_name).join(", ")}`} title={`Feature image of example of a Space with lists ${spaceLists.lists.map((list: any) => list.list_name).join(", ")}`} width={"1280"} height={"720"} /> */}
                                        <div style={{padding: 20, paddingTop: 0}}>
                                            <TemplatePreview templateData={spaceTemplate} fullWidth profile={undefined} station={[]} />
                                        </div>
                                    </VStack>
                                }
                            </Col>
                        </Row>

                <VSpacer height={40} />
            </Container>

            <MainFooter />
        </CreateAccountContainer>
    );
}

const BlogSplashImage = styled.img`
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 1px solid rgba(0,0,0,0.06);
    max-width: 1280px;
    max-height: 720px;
    box-shadow: rgba(50, 50, 93, 0.05) 0px 50px 100px -20px, rgba(0, 0, 0, 0.03) 0px 30px 60px -30px;
`;

function BuildingProfileLoader({progress, mini = false}: {
    progress: number,
    mini?: boolean
}): JSX.Element {
    return (
       <HStack>
            <VStack jCenter>
                <div style={{width: mini ? 200 : 400, padding: 20}}>
                    <Lottie animationData={building_blocks} loop={true}/>
                </div>

                <HStack>
                    <VStack>
                        <BodyFont><BodyFontHighlighted>Building your profile... </BodyFontHighlighted><br /> {!mini && "(Don't close this page)"}</BodyFont>

                        <ProgressBar now={progress} label={`${progress}%`} visuallyHidden />
                    </VStack>
                </HStack>
            </VStack>
       </HStack>
    )
}



export default CreateAccountView;