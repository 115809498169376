import React, { createContext, useContext, useState, ReactNode, useEffect, useRef } from 'react';
import { ProfileType, TaskType, SpaceStationType, SpaceType } from './Types/HeroTypes';
import ItemPoster from './views/Marketplace/ItemPoster';

type SelectedItemContextType = {
  selectedItem: TaskType | null;
  setSelectedItem: (item: TaskType | null, element?: HTMLButtonElement) => void;
  selectedRef: HTMLButtonElement | HTMLDivElement | null;
};

const SelectedItemContext = createContext<SelectedItemContextType | undefined>(undefined);

export const useSelectedItem = () => {
  const context = useContext(SelectedItemContext);
  if (!context) {
    throw new Error('useSelectedItem must be used within a SelectedItemProvider');
  }
  return context;
};

interface SelectedItemProviderProps {
  children: ReactNode;
  profile: ProfileType | null | undefined;
  spaces: SpaceType[];  // Replace with your actual type
  station: SpaceStationType[];  // Replace with your actual type
  setProfile: (profile: ProfileType) => void;  // Replace with your actual type
  setIsCreateSpaceOpen: (isOpen: boolean) => void;
  StoreFrontLeftColRef: null | React.RefObject<HTMLDivElement>;  // Replace with your actual type
}


export const SelectedItemProvider: React.FC<SelectedItemProviderProps> = ({
  children,
  profile,
  spaces,
  station,
  setProfile,
  setIsCreateSpaceOpen,
  StoreFrontLeftColRef,
}) => {
  const [selectedItem, setSelectedItemState] = useState<TaskType | null>(null);
  const [selectedRef, setSelectedRef] = useState<HTMLButtonElement | HTMLDivElement | null>(null);
  const posterRef = useRef<HTMLDivElement | null>(null);

  const setSelectedItem = (
    item: TaskType | null,
    element?: HTMLButtonElement | HTMLDivElement
  ) => {
    setSelectedItemState(item);
    if (element) {
      setSelectedRef(element);
    }
  };

  const findScrollableParent = (element: HTMLDivElement | HTMLButtonElement | null): HTMLElement | null => {
    let current: HTMLElement | null = element;
    while (current && current !== document.body) {
      const style = window.getComputedStyle(current);
      if (style.overflow === 'scroll' || style.overflowY === 'scroll') {
        return current;
      }
      current = current.parentNode as HTMLElement | null;
    }
    return null;
  };

  useEffect(() => {
    if (selectedItem !== null && selectedRef && posterRef.current) {
      const rect = selectedRef.getBoundingClientRect();
      const poster = posterRef.current;
  
      poster.style.position = 'absolute';
      poster.style.left = `${rect.left}px`;
      poster.style.top = `${rect.top}px`;
    }
  }, [selectedItem, selectedRef]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (posterRef.current && !posterRef.current.contains(event.target as Node)) {
        setSelectedItem(null);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    let initialLeftFromSelectedRef: number | null = null;

    const updatePosition = () => {
      if (posterRef.current && selectedRef) {
        const selectedRect = selectedRef.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        const windowWidth = window.innerWidth;
        const posterHeight = 400;  // Assuming the height of the poster is 400
        const posterWidth = 400;   // Assuming the width of the poster is 400
    
        if (initialLeftFromSelectedRef === null) {
          initialLeftFromSelectedRef = selectedRect.left;
        }
    
        // Horizontal Position
        let topPosition = selectedRect.top + window.pageYOffset;
        posterRef.current.style.top = `${topPosition}px`;
        posterRef.current.style.left = `${selectedRect.left}px`;
    
        const horizontalOverflow = (selectedRect.left + posterWidth) - windowWidth;
    
        if (horizontalOverflow > 0) {
          const adjustedLeft = selectedRect.left - horizontalOverflow;
          posterRef.current.style.left = `${adjustedLeft}px`;
        } else if (initialLeftFromSelectedRef + posterWidth <= windowWidth) {
          posterRef.current.style.left = `${initialLeftFromSelectedRef}px`;
        }
    
        // Vertical Position
        const verticalOverflowBottom = (topPosition + posterHeight) - (windowHeight + window.pageYOffset);
    
        if (verticalOverflowBottom > -200) {
          const adjustedTop = topPosition - verticalOverflowBottom - 200;
          posterRef.current.style.top = `${adjustedTop}px`;
        }
    
        const verticalOverflowTop = window.pageYOffset - topPosition;
    
        if (verticalOverflowTop > 0) {
          const adjustedTop = topPosition + verticalOverflowTop;
          posterRef.current.style.top = `${adjustedTop}px`;
        }
      }
    };

    const scrollableParent = findScrollableParent(selectedRef);

    if (scrollableParent) {
      scrollableParent.addEventListener('scroll', updatePosition);
    }
    
    window.addEventListener('resize', updatePosition);

    updatePosition();
    return () => {
      window.removeEventListener('resize', updatePosition);
      if (scrollableParent) {
        scrollableParent.removeEventListener('scroll', updatePosition);
      }
    };
  }, [selectedItem, selectedRef]);
  
  return (
    <SelectedItemContext.Provider value={{ selectedItem, setSelectedItem, selectedRef }}>
      {children}
      {selectedItem !== null && (
        <div 
          ref={posterRef}
          style={{ position: 'absolute'}} 
          // className="item-poster-wrapper"
        >
          <ItemPoster
            profile={profile}
            setSelectedItem={setSelectedItem}
            miniSelector={true}
            spaces={spaces}
            station={station}
            setProfile={setProfile}
            setIsCreateSpaceOpen={setIsCreateSpaceOpen}
            StoreFrontLeftColRef={StoreFrontLeftColRef}
            selectedItem={selectedItem}
          />
        </div>
      )}
    </SelectedItemContext.Provider>
  );
};