class ColorService {
    primaryDark = "#171719"
    primaryPink = "#e82f64"
    darkerPink = "#D264B6"
    primaryBlue = "#067AFE"
    primaryGreen = "#1FC421"
    primaryPurple = "#A480CF"
    brandPink = "#FF499E";
}

export default new ColorService();