import React, { useEffect, useRef, useState } from "react";
import styled from 'styled-components'

import Colors from "../../services/Colors.js";
import { HeroAPI } from "../../services/HeroAPI";
import HStack from "../../components/utils/HStack";
import VStack from "../../components/utils/VStack";
import HSpacer from "../../components/utils/HSpacer";

import "../../index.css";

import CircularProgress from '@mui/material/CircularProgress';

import { analytics, app, auth } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { CreateListRequestBody, CreateListResponse, CreateSpaceRequestBody, CreateSpaceResponse, MessageGroupType, PageType, ProfileSpaceRefType, ProfileType, SpaceMemberRefType, SpaceStationType, SpaceType, TaskType } from "../../Types/HeroTypes";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ExpandCircleDownRoundedIcon from '@mui/icons-material/ExpandCircleDownRounded';

import VSpacer from "../../components/utils/VSpacer";
import { Box, Divider, Modal, Skeleton } from "@mui/material";

import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/SearchRounded';

import SignIn from "../Auth/SignIn";
import CreateAccount from "../Auth/CreateAccount";

import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import IconButton from '@mui/material/IconButton';


import Slide from '@mui/material/Slide';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Helper from "../../services/Helper";
import { Timestamp, setDoc } from "@firebase/firestore";
import { logEvent } from "firebase/analytics";
import slugify from "react-slugify";


function truncate(str: string, n: number){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

interface SearchComponentProps {
  searchQuery: string;
  setSearchQuery: React.Dispatch<React.SetStateAction<string>>;
}

const SearchContainer = styled.div`
  border: 1px solid rgba(0,0,0,0.06);
  border-radius: 15px;
  display: flex;
  align-items: center;
`;

const StyledSearchField = styled(TextField)`
  flex: 1;
  .MuiOutlinedInput-root {
    border-radius: 15px;
    &:hover {
      border-color: ${Colors.primaryPink};
    }
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border-color: ${Colors.primaryPink};
      }
    }
  }
`;

const SearchComponent: React.FC<SearchComponentProps> = ({ searchQuery, setSearchQuery }) => {
  return (
    <SearchContainer>
      <StyledSearchField
        variant="outlined"
        placeholder="Search your Spaces"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </SearchContainer>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button`
& {
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
  box-sizing: border-box;
  color: #121212;
  cursor: pointer;
  display: inline-flex;
  flex: 1 1 auto;
  font-family: Inter,sans-serif;
  font-size: 1rem;
  font-weight: 700;
  justify-content: center;
  line-height: 1;
  margin: 0;
  outline: none;
  padding: 1rem 1.2rem;
  text-align: center;
  text-decoration: none;
  transition: box-shadow .2s,-webkit-box-shadow .2s;
  white-space: nowrap;
  border: 0;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

&:hover {
  box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
}
`;

const StyledTextField = styled(TextField)`
  flex: 1;
  & {
    align-items: center;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
    box-sizing: border-box;
    color: #121212;
    cursor: pointer;
    display: inline-flex;
    flex: 1 1 auto;
    font-family: Inter,sans-serif;
    font-size: 1.2rem;
    font-weight: 700;
    justify-content: center;
    line-height: 1;
    margin: 0;
    outline: none;
    padding: 1rem 1.2rem;
    text-align: start;
    text-decoration: none;
    transition: box-shadow .2s,-webkit-box-shadow .2s;
    white-space: nowrap;
    border: 0;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: 100% !important;
  }
  
  .MuiInputBase-root {
    width: 100%;
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before,
  .MuiInput-underline:hover:before {
    border-bottom: none !important;
  }

  .MuiInputBase-input {
    border: 0 !important;
    box-shadow: none !important;
    outline: none !important;
    width: 100%;
  }


  .MuiOutlinedInput-root {
    border: none !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  &:focus,
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: none !important;
    outline: none !important;
  }


  &:focus, &:active {
    border: 0;
    outline: none;
  }
`;

interface CreateEntityProps {
    onCreate: (name: string) => void;
    label: string;
    tooltip: string;
    placeholder: string;
    isList?: boolean;
    isLoading?: boolean;
    progressLabel?: string | null;
}
  
const CreateEntityButton: React.FC<CreateEntityProps> = ({ onCreate, label, tooltip, placeholder, isList = false, isLoading = false, progressLabel = null }) => {
    const [isInputVisible, setInputVisible] = useState(false);
    const [newEntityName, setNewEntityName] = useState("");
    const inputRef = useRef<HTMLInputElement>(null);
  
    useEffect(() => {
      if (isInputVisible && inputRef && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isInputVisible]);
  
    const handleClick = () => {
      if (isInputVisible && newEntityName !== "") {
        onCreate(newEntityName);
        setNewEntityName("");
      }
      setInputVisible(!isInputVisible);
    };
  
    return (
    <ButtonContainer style={{ pointerEvents: isLoading ? 'none' : 'auto' }}>
        {isInputVisible ? (
          <>
            <StyledTextField
              variant="outlined"
              value={newEntityName}
              onChange={(e) => setNewEntityName(e.target.value)}
              inputRef={inputRef}
              onBlur={() => {
                if (newEntityName.trim() === "") {
                  setInputVisible(false);
                }
              }}
              placeholder={placeholder}
            />
            <HSpacer width={10} />
            <Tooltip title={tooltip} arrow style={{zIndex: 9999999999999999999999999990}}>
                <IconButton onClick={handleClick}>
                    <AddCircleOutlineIcon />
                </IconButton>
            </Tooltip>
          </>
        ) : (
          <StyledButton onClick={handleClick} style={isList ? {padding: "6px 10px"} : {}}>
            {isLoading ? (
                    <VStack maxWidth jCenter>
                        <HStack aCenter maxWidth dynamic>
                            <CircularProgress size={18} style={{ color: Colors.primaryPink }} />
                        </HStack>

                        {
                            progressLabel && 
                            <HStack aCenter maxWidth dynamic>
                                <small
                                    style={{fontSize: 12, fontWeight: 300, marginTop: 10}}
                                >{progressLabel}</small>
                            </HStack>
                        }
                    </VStack>
                ) : (
                    <>
                        <AddIcon />
                        <HSpacer width={10} />
                        <span>{label}</span>
                    </>
                )}
          </StyledButton>
        )}
      </ButtonContainer>
    );
};

const SpacePickerModal = {
    position: 'relative' as 'relative',
    width: "500px",
    bgcolor: 'background.paper',
    boxShadow: 24,
    height: "70vh",
    overflow: "scroll",
    borderRadius: 1,
    zIndex: 1000000000000,
    padding: 3,
    margin: "0 auto",
    marginTop: "15vh"
};


const PLACEHOLDER_SPACE_IMAGES = [
  "https://cdn.hero.page/i/c0efe4-11dc-d546-1db-ab76dca7625-samoshasfallen-an-wallpaper-of-subtle-squigly-shapes-interlocki-1c96062e-aa83-46bc-afdf-455a8f03fd44-1.webp"
];

function getRandomSpaceImage(): string {
    const randomIndex = Math.floor(Math.random() * PLACEHOLDER_SPACE_IMAGES.length);
    return PLACEHOLDER_SPACE_IMAGES[randomIndex];
}

const db = app.firestore();

function PostSpaceSelector({
    isPostEnabled,
    profile, 
    spaces, 
    station, 
    selectedSpace, 
    setSelectedSpace,
    isPickerOpenDefault = false,
    label = "Choose a Space",
    miniSelector = false,
    forMiniCreator = false,
    setConfigBucket,
    setProfile,
    itemPosted = false,
    setConfigs
}: {
    isPostEnabled: boolean,
    profile: ProfileType | undefined | null,
    spaces: SpaceType[],
    station: SpaceStationType[],
    selectedSpace: SpaceType | null,
    setSelectedSpace: any,
    isPickerOpenDefault?: boolean,
    miniSelector?: boolean,
    label?: string,
    forMiniCreator?: boolean,
    setConfigBucket: any,
    setProfile: any,
    itemPosted: boolean,
    setConfigs: any
}): JSX.Element {
    const [user] = useAuthState(auth);
    const [isPickerOpen, setIsPickerOpen] = useState(true);
    
    const [searchQuery, setSearchQuery] = useState<string>("");

    const [cachedAPIToken, setCachedAPIToken] = useState<string | null>(null)

    const [isDropdownVisible, setDropdownVisible] = useState(false);

    const [isCreateSpaceLoading, setIsCreateSpaceLoading] = useState(false);
    const [isCreateListLoading, setIsCreateListLoading] = useState(false);

    const [progressLabel, setProgressLabel] = useState<string | null>(null);

    const [selectedSpacePages, setSelectedSpacePages] = useState<PageType[] | null>(null);

    const getSpacePages = async (spaceID: string) =>  {
        try {
            const SpacePagesRef = db.collection("Spaces").doc(`${spaceID}`).collection("Pages");

            const SpacePagesSnapshot = await SpacePagesRef.get();

            const PageData = SpacePagesSnapshot.docs.map((page) => Helper.PageDocToObject(page)).filter((page) => page.workflow_type !== "ai_application");

            setSelectedSpacePages(PageData);
        } catch (err) {
            console.log(err);
        }
    }
  
    const [isListSelecting, setListSelecting] = useState(false);
    const [selectedSpaceItem, setSelectedSpaceItem] = useState<SpaceType | null>(null);

    const handleSpaceItemClick = (spaceItem: SpaceType | null) => {
        if (spaceItem && spaceItem.id) {
            setSelectedSpacePages(null)
            setSelectedSpaceItem(spaceItem);
            setListSelecting(true);
            getSpacePages(spaceItem.id)
        } else {
            setSelectedSpaceItem(spaceItem);
            setListSelecting(false);
        }
    };

    const sortedSpaces = getSortedSpace(spaces, station, searchQuery);

    const [tempSpaceID, setTempSpaceID] = useState<string | null>(null);

    const getNewestSpaceSortIndex = () => {
      if (station.length === 0) {
          return 1000000;
      }

      return Math.max(...station.map(o => o.sortIndex !== undefined ? o.sortIndex : 0)) + 100
  }

    const callCreateSpace = async (name: string, profile: ProfileType) => {
        setIsCreateSpaceLoading(true);
        
        const truncatedLabel_Name = truncate(name, 25);
        setProgressLabel(`Creating ${truncatedLabel_Name}${truncatedLabel_Name.endsWith('...') ? '' : '...'}`);


        try {
            if (profile.uid) {
                // const API_TOKEN = cachedAPIToken || (await db.collection("apiKeys").where("userUid", "==", profile.uid).get()).docs[0].id;
                
                // if (API_TOKEN) {
                //     const payload: CreateSpaceRequestBody = {
                //         name,
                //         isPublic: true,
                //         img: getRandomSpaceImage()
                //     };
                    
                //     const { isPublic, img } = payload;
                //     const res: CreateSpaceResponse = await new HeroAPI(API_TOKEN).createSpace(name, isPublic, img);
                    
                    // setProgressLabel(`Adding a list into ${truncatedLabel_Name}${truncatedLabel_Name.endsWith('...') ? '' : '...'}`);

                    // await callCreateList("Items", res.id, profile, true, API_TOKEN);
                    
                    // setTempSpaceID(res.id);  
                // }

                const newSortIndex = getNewestSpaceSortIndex();

                const SpaceRef = db.collection("Spaces").doc();
                const MessageGroupRef = db.collection("MessageGroups").doc();
                const SpacePageRef = db.collection("Spaces").doc(SpaceRef.id).collection("Pages").doc();
                const StationSpaceRef = db.collection("Profiles").doc(profile.uid).collection("Station").doc(SpaceRef.id);
                const ProfileSpaceRef = db.collection("Profiles").doc(profile.uid).collection("Spaces").doc(SpaceRef.id);
                const SpaceMemberProfileRef = db.collection("Spaces").doc(SpaceRef.id).collection("Members").doc(profile.uid);
    
                const SpaceData: SpaceType = {
                    name: name,
                    img: getRandomSpaceImage(),
                    slug: slugify(name),
                    sortIndex: newSortIndex,
                    createdAt: Timestamp.fromDate(new Date()),
                    ownerUID: profile.uid,
                    messageGroupID: MessageGroupRef.id,
                    muted_uids: [],
                    shareable_link: "",
                    originalSpaceIDTemplate: "",
                    dateLiked: Timestamp.fromDate(new Date())
                };
    
                await setDoc(SpaceRef, SpaceData);
    
                const MessageGroupData: MessageGroupType = {
                    spaceID: SpaceRef.id,
                    name: "",
                    img: "",
                    newestMessageTime: Timestamp.fromDate(new Date()),
                    newestMessage: "Send the first message!",
                    createdAt: Timestamp.fromDate(new Date()),
                    ownerUID: profile.uid,
                    members: [profile.uid],
                    muted_uids: [],
                    active_user_uids: [],
                    profiles_marked_as_read: [profile.uid]
                };
    
                await setDoc(MessageGroupRef, MessageGroupData);
    
              //   const PageData: PageType = {
              //     name: "Items",
              //     color: "#e82f64",
              //     sortIndex: 10000000.0,
              //     spaceID: SpaceRef.id,
              //     groupID: "",
              //     createdAt: Timestamp.fromDate(new Date()),
              //     workflow_type: "items"
              // };
    
              //   await setDoc(SpacePageRef, PageData);
    
                const StationData: SpaceStationType = {
                    isWidget: false,
                    sortIndex: newSortIndex,
                    id: SpaceRef.id
                };
    
                await setDoc(StationSpaceRef, StationData);
    

                const ProfileSpaceData: ProfileSpaceRefType = {
                    dateJoined: Timestamp.fromDate(new Date())
                };
    
                await setDoc(ProfileSpaceRef, ProfileSpaceData);
    
                const SpaceMemberProfileData: SpaceMemberRefType = {
                    dateJoined: Timestamp.fromDate(new Date())
                };
    
                await setDoc(SpaceMemberProfileRef, SpaceMemberProfileData);

                const SocialSpaceRef = db.collection("Profiles").doc(`${profile.uid}`).collection("SocialSpaces").doc(SpaceRef.id);

                await setDoc(SocialSpaceRef, { 
                    dateAddedToProfile: Timestamp.fromDate(new Date()),
                    slug: slugify(name)
                }, { merge: true });
    

                logEvent(analytics, "added_space_from_invitation", {});

                setProgressLabel(`Adding a list into ${truncatedLabel_Name}${truncatedLabel_Name.endsWith('...') ? '' : '...'}`);

                await callCreateList("Items", SpaceRef.id, profile, true);
                
                setTempSpaceID(SpaceRef.id);  
            }
        } catch (err) {
            console.log(err);
        }
    };

    async function getNewPageSortIndex(spaceId: string) {
      // Reference to the Pages collection within the specified Space
      const pagesRef = db.collection("Spaces").doc(spaceId).collection("Pages");
  
      // Query to get the latest sorted Page, if any
      const latestPageSnapshot = await pagesRef.orderBy("sortIndex", "desc").limit(1).get();
  
      // If there are no other docs, return 100000; otherwise, return the latest sortIndex + 100
      return latestPageSnapshot.empty ? 100000 : latestPageSnapshot.docs[0].data().sortIndex + 100;
  }

    const callCreateList = async (name: string, spaceId: string, profile: ProfileType, addToArray: boolean = false) => {
        setIsCreateListLoading(true);
        
        try {
            if (profile.uid) {
              const newSortIndex = await getNewPageSortIndex(spaceId);
                // const API_TOKEN = (await db.collection("apiKeys").where("userUid", "==", profile.uid).get()).docs[0];
                
                // const API_TOKEN = cachedAPIToken || FETCHED_API_token || (await db.collection("apiKeys").where("userUid", "==", profile.uid).get()).docs[0].id;

                // if (API_TOKEN) {
                //     const payload: CreateListRequestBody = {
                //         spaceId: spaceId,
                //         color: Colors.primaryPink,
                //         name: name                        
                //     };
                    
                //     const { color } = payload;

                //     const res: CreateListResponse = await new HeroAPI(API_TOKEN).createList(spaceId, name, color);
                    
                //     const pageToAdd = Object.assign({}, res.page, {
                //         id: res.id
                //     });

                    // if (addToArray && selectedSpacePages !== null) {
                    //     setSelectedSpacePages((oldPages) => {
                    //         if (oldPages){
                    //             return [pageToAdd, ...oldPages];
                    //         } else {
                    //             return null;
                    //         }
                    //     })
                    // }

                //     setIsCreateListLoading(false);
                // }

                const SpacePageTaskRef = db.collection("Spaces").doc(spaceId).collection("Pages").doc();

                const PageData: PageType = {
                    name: name,
                    color: Colors.primaryPink,
                    spaceID: spaceId,
                    sortIndex: newSortIndex,
                    createdAt: Timestamp.fromDate(new Date()),
                    workflow_type: "items"

                }
        
                await setDoc(SpacePageTaskRef, PageData);
                
                await getSpacePages(spaceId);

                // if (addToArray && selectedSpacePages !== null) {
                //     setSelectedSpacePages((oldPages) => {
                //         if (oldPages){
                //             return [PageData, ...oldPages];
                //         } else {
                //             return null;
                //         }
                //     })
                // }

                setIsCreateListLoading(false);

            }
        } catch (err) {
            console.log(err);
        }
    };

    const spacesRef = useRef(spaces);

    useEffect(() => {
      spacesRef.current = spaces;
    }, [spaces]);
    
    useEffect(() => {
      let intervalId: NodeJS.Timeout;
    
      if (tempSpaceID !== null) {
        intervalId = setInterval(() => {
          const foundSpace = spacesRef.current.find(space => space.id === tempSpaceID);
          if (foundSpace) {
            clearInterval(intervalId);
            handleSpaceItemClick(foundSpace);
            setIsCreateSpaceLoading(false);
            setTempSpaceID(null); // Reset to null
          }
        }, 1000);
      }
    
      return () => {
        if (intervalId) clearInterval(intervalId);
      };
    }, [tempSpaceID]);

    const isAnythingLoading = isCreateSpaceLoading || isCreateListLoading;

    const resetPostDropdown = () => {
        if (!isAnythingLoading) {
            setSelectedSpacePages(null);
            setListSelecting(false);
            setSelectedSpaceItem(null);
        }
    }

    const [isSignInActive, setIsSignInActive] = useState(false);

    return (
        <VStack maxWidth
            onMouseEnter={miniSelector ? () => {} : () => setDropdownVisible(true)}
            onMouseLeave={miniSelector ? () => {} : () => setDropdownVisible(false)}
            style={isPostEnabled ? {} : profile ? {opacity: 0.6, pointerEvents: "none"} : {}}
        >
              <MainDiv
                    onClick={() => {}}
                    onMouseLeave={() => resetPostDropdown()}
                >
                    {
                        !miniSelector ?
                        isPickerOpenDefault ?
                        null :
                        selectedSpace !== null ?
                        <SpaceItemPicker 
                            onClick={() => setIsPickerOpen(true)}
                            space={selectedSpace}
                            forMiniCreator={forMiniCreator}
                        />
                        :
                        <SpaceItemPickerPlaceholder
                            onClick={() => setIsPickerOpen(true)}
                            forMiniCreator={forMiniCreator}
                        />:
                        null
                    }
                    <DropdownDiv style={!profile ? {height: 530} : {}} className={`dropdown ${(!itemPosted && miniSelector) || isDropdownVisible || isAnythingLoading ? 'visible' : ''} ${miniSelector ? "mini-selector" : ""}`}>
                        <DropdownContent>
                            {
                                profile ?
                                <VStack style={{height: "100%", display: "flex"}}>
                                        <CustomSlide direction="right" in={!isListSelecting} mountOnEnter unmountOnExit  className={isAnythingLoading ? 'parent-disabled' : ''}>
                                            <VStack style={{height: "100%", display: "flex"}}>
                                                <VSpacer height={10} />
                                                <MenuTitle style={{textAlign: "center", padding: "10px 0"}}>
                                                    {label}
                                                </MenuTitle>
                                                
                                                <VSpacer height={10} />

                                                <Divider style={{width: "100%"}} />

                                                <VSpacer height={10} />

                                                <SearchComponent searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
                                                
                                                <VSpacer height={10} />

                                                <ScrollableSection>
                                                    <VSpacer height={10} />
                                                    <div>
                                                    {
                                                        sortedSpaces.length > 0 ? 
                                                        sortedSpaces.map((space, i) => (
                                                            <SpaceItem setIsPickerOpen={setIsPickerOpen} space={space} selectedSpace={selectedSpace} setSelectedSpace={handleSpaceItemClick} key={`${space.id}`} showName={true} />
                                                        )) : 
                                                        <div style={{ textAlign: "center", fontWeight: 400, padding: "0 10px", fontSize: 14 }}>No spaces found for your query</div>
                                                    }
                                                    </div>
                                                    <VSpacer height={10} />
                                                </ScrollableSection>

                                                <CreateEntityButton 
                                                    onCreate={(name) => {
                                                        if (profile) {
                                                            callCreateSpace(name, profile)
                                                        }
                                                    }} 
                                                    isLoading={isCreateSpaceLoading}
                                                    label="Create new Space" 
                                                    tooltip="Create Space" 
                                                    placeholder="Enter Space name"
                                                    progressLabel={progressLabel}
                                                />
                                                <VSpacer height={10} />
                                            </VStack>
                                        </CustomSlide>

                                        <CustomSlide direction="left" in={isListSelecting} mountOnEnter unmountOnExit>
                                            <VStack style={{height: "100%", display: "flex"}}>
                                                <VSpacer height={10} />
                                                <MenuTitle style={{textAlign: "center", padding: "10px 0"}}>
                                                    Choose a list for your items
                                                </MenuTitle>

                                                <VSpacer height={10} />

                                                <Divider style={{width: "100%"}} />

                                                <VSpacer height={10} />

                                                {
                                                    selectedSpaceItem && 
                                                    <div style={{pointerEvents: "none"}}>
                                                        <SpaceItem setIsPickerOpen={setIsPickerOpen} space={selectedSpaceItem} selectedSpace={selectedSpaceItem} setSelectedSpace={handleSpaceItemClick} key={`${selectedSpaceItem && selectedSpaceItem ? selectedSpaceItem.id : '2'}`} showName={true} />
                                                    </div>
                                                }

                                                <ScrollableSection>
                                                    <VSpacer height={10} />
                                                {
                                                    selectedSpacePages ? 
                                                    selectedSpacePages.length > 0 ?
                                                    <div>
                                                        {
                                                            selectedSpacePages.map((page: PageType, index: number) => (
                                                                <ListSelector key={index + 2} page={page} setPageForConfig={() => {
                                                                    const schema = {
                                                                        space: selectedSpaceItem,
                                                                        page: page
                                                                    };

                                                                    setConfigBucket(schema);

                                                                    resetPostDropdown();
                                                                }}/>
                                                            ))
                                                        }
                                                    </div> :
                                                    <div style={{ textAlign: "center", fontWeight: 400, padding: "0 10px", fontSize: 14 }}>This Space has no lists, create one!</div>
                                                    :
                                                    <VStack maxWidth>
                                                        <VSpacer height={20} />
                                                        <Skeleton variant="rectangular" width={"100%"} height="50px" style={{borderRadius: "10px"}} />
                                                        <VSpacer height={10} />
                                                        <Skeleton variant="rectangular" width={"100%"} height="50px" style={{borderRadius: "10px"}} />
                                                        <VSpacer height={10} />
                                                        <Skeleton variant="rectangular" width={"100%"} height="50px" style={{borderRadius: "10px"}} />
                                                        <VSpacer height={10} />
                                                        <Skeleton variant="rectangular" width={"100%"} height="50px" style={{borderRadius: "10px"}} />
                                                    </VStack>
                                                }
                                                    <VSpacer height={10} />
                                                </ScrollableSection>

                                                <CreateEntityButton 
                                                    onCreate={(name) => {
                                                        if (profile && selectedSpaceItem && selectedSpaceItem.id) {
                                                            callCreateList(name, selectedSpaceItem.id, profile, true)
                                                        }
                                                    }} 
                                                    label="Create new List" 
                                                    tooltip="Create List" 
                                                    placeholder="Enter List name"
                                                    isList={true}
                                                    isLoading={isCreateListLoading}
                                                />
                                                <VSpacer height={20} />
                                                <UnstyledButton onClick={() => handleSpaceItemClick(null)}>Go back</UnstyledButton>
                                            </VStack>
                                        </CustomSlide>
                                </VStack> :
                                <VStack style={{height: "100%", display: "flex"}}>
                                    <CustomSlide direction="right" in={isSignInActive} mountOnEnter unmountOnExit>
                                        <VStack maxWidth>
                                            <VSpacer height={10} />
                                            <MenuTitle style={{textAlign: "center", padding: "10px 0"}}>
                                                Sign in to post item
                                            </MenuTitle>
                                            
                                            <VSpacer height={10} />

                                            <Divider style={{width: "100%"}} />

                                            <VSpacer height={10} />

                                            <SignIn mini={true} />

                                            <VSpacer height={10} />

                                            <SignInInlineLinkFont onClick={() => setIsSignInActive(false)}>Create an Account</SignInInlineLinkFont>
                                        </VStack>
                                    </CustomSlide>
                                    <CustomSlide direction="right" in={!isSignInActive} mountOnEnter unmountOnExit>
                                        <VStack maxWidth>
                                            <VSpacer height={10} />
                                            <MenuTitle style={{textAlign: "center", padding: "10px 0"}}>
                                                Sign up for free to {miniSelector ? "save" : "post"} items
                                            </MenuTitle>
                                            
                                            <VSpacer height={10} />

                                            <Divider style={{width: "100%"}} />

                                            <VSpacer height={10} />

                                            <CreateAccount setProfile={setProfile} mini={true} />

                                            <VSpacer height={10} />

                                            <SignInInlineLinkFont onClick={() => setIsSignInActive(true)}>Sign in instead</SignInInlineLinkFont>
                                        </VStack>
                                    </CustomSlide>
                                </VStack>
                            }
                        </DropdownContent>
                    </DropdownDiv>
                </MainDiv>
        </VStack>
    );
}

const SignInInlineLinkFont = styled.p `
    text-decoration: none;
    font-size: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    text-align: center;
    font-weight: 900;
    color: ${Colors.primaryDark};
    cursor: pointer;

    &:hover {
        color: ${Colors.primaryPink};
    }
`;

function ListSelector({
    page,
    setPageForConfig
}: {
    page: PageType,
    setPageForConfig: any
}): JSX.Element {

    return (
        <ListSelectorContainer>
                <HStack jStart aCenter>
                    <CircleDot color={page.color} />
                    <HSpacer width={5} />
                    <ListSelectorName className="hide-bit-of-name">
                            {page.name} 
                    </ListSelectorName>
                </HStack>

                <Tooltip 
                    title={<div style={{fontSize: 14}}>This will add the item(s) to the "{page.name}" list</div>} 
                    placement="right"
                    style={{zIndex: 999999999999999999999999999999990}}
                >
                    <AddItemButton onClick={() => {
                        setPageForConfig(page);
                    }}>
                        Post
                    </AddItemButton>
                </Tooltip>
        </ListSelectorContainer>
    )
}


const UnstyledButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  color: inherit;
  font: inherit;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0,0,0,0.5);
  margin-bottom: 25px;
`;

const CustomSlide = styled(Slide)`
    height: 100% !important;
    min-height: 100%;
`;

const ScrollableSection = styled.div`
  overflow-y: auto;
  flex-grow: 1;
`;

const MenuTitle = styled.span`
    font-size: 16px;
    font-weight: 700;
    color: ${Colors.primaryDark};
`;

const MenuSubTitle = styled.span`
    font-size: 14px;
    color: ${Colors.primaryDark};
    opacity: 0.6;
`;

function getSortedSpace(spaces: SpaceType[], station: SpaceStationType[], searchQuery: string): SpaceType[] {
    let sorted_spaces: SpaceType[] = [...spaces];
  
    // Trim and check if searchQuery is not empty
    const trimmedQuery = searchQuery.trim();
    if (trimmedQuery !== "") {
      sorted_spaces = sorted_spaces.filter((space) => space.name.toLowerCase().includes(trimmedQuery.toLowerCase()));
    }
  
    sorted_spaces = sorted_spaces.sort((a: SpaceType, b: SpaceType) => {
      if (a.id !== null && a.id !== undefined && b.id !== null && b.id !== undefined) {
        let aI = station.findIndex((s) => s.id === a.id);
        let bI = station.findIndex((s) => s.id === b.id);
  
        if (aI === -1 || bI === -1) {
          return 0;
        } else {
          return station[aI].sortIndex - station[bI].sortIndex;
        }
      } else {
        return 0;
      }
    });
  
    return sorted_spaces;
}

const AddItemButton = styled.button`
position: absolute;
right: 10px;
visibility: hidden;
background-color: #FFFFFF;
border: 0;
border-radius: .5rem;
box-sizing: border-box;
color: #111827;
font-family: "Inter var",ui-sans-serif,system-ui,-apple-system,system-ui,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
font-size: .875rem;
font-weight: 600;
line-height: 1.25rem;
padding: .75rem 1rem;
text-align: center;
text-decoration: none #D1D5DB solid;
text-decoration-thickness: auto;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
cursor: pointer;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
white-space: nowrap;

&:hover {
  background-color: rgb(249,250,251);
}

&:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

&:focus-visible {
  box-shadow: none;
}
`

const ListSelectorName = styled.span`
    font-weight: 500;
    font-size: 14px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 400px;
    padding: 5px 0;
`;

const ModalText = styled.span`
    font-weight: 500;
    font-size: 14px;

    max-width: 400px;
    padding: 5px 0;
`;

const ListSelectorContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: start;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    background: 0;
    border: 0;
    outline: none;


    &:hover {
        // box-shadow: rgb(0 0 0 / 4%) 0px 20px 25px -5px, rgb(0 0 0 / 1%) 0px 10px 10px -5px;
        background: rgba(0,0,0,0.01);
        & > button {
            visibility: visible;
        }

        &  .hide-bit-of-name {
            max-width: 250px !important;
        }
    }
`;


interface CircleDotType {
    color: string;
  }
  
const _CircleDot = styled.div<CircleDotType>;

const CircleDot = _CircleDot`
    height: 15px;
    width: 15px;
    background-color: ${props => props.color};
    border-radius: 50%;
    display: block;
    margin: 10px;
    padding: 8px;
    margin-left: 6px;
`;

const SpaceItemWrapper = styled.button`
    position: relative;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 15px;
  outline: none;
  border: 0;
  background: 0;
  box-shadow: 0;
  position: relative;

  &:hover {
    background-color: rgba(0,0,0,0.05);
    opacity: 1 !important;
  }

  &:hover svg {
    visibility: visible !important;
  }
`;

interface SpaceItemPickerWrapperProps {
    forMiniCreator?: boolean;
  }
  
const SpaceItemPickerWrapper = styled.button<SpaceItemPickerWrapperProps>`
    /* Conditional styles based on forMiniCreator */
    ${props => props.forMiniCreator ? `
        align-items: center;
        background-color: #fff;
        border-radius: 40px;
        box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
        box-sizing: border-box;
        color: #121212;
        cursor: pointer;
        display: inherit;
        font-family: Inter,sans-serif;
        font-size: 18px;
        font-weight: 900 !important;
        justify-content: start;
        line-height: 1;
        margin: 10px 0;
        outline: none;
        padding: 12px 20px;
        text-align: start;
        text-decoration: none;
        transition: all .2s,-webkit-box-shadow .2s;
        border: 0;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        display: flex;
        align-items: center !important;
        width: auto !important;  // Add this line
        // border: 2px solid #171717;
        & > svg {
            font-size: 24px;
            // margin-right: 10px;
        }

        // box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
        box-shadow: rgba(0,0,0,0.9) 0 0 0px 3px, transparent 0 0 0 0;

        &:hover {
            // box-shadow: #121212 0 0 0 3px, transparent 0 0 0 0;
            box-shadow: 
                #FF499E 0px 0px 0px 2px, 
                #D264B6 0px 0px 0px 4px, 
                #A480CF 0px 0px 0px 5px, 
                #779BE7 0px 0px 0px 6px, 
                #49B6FF 0px 0px 0px 7px;

        }
    ` :
    `
    
    `
    }
`;

const FancyPantsButton = styled.button`
& {
  position: relative;
  width: 120px;
  height: 40px;
  background-color: #000;
  display: flex;
  align-items: center;
  color: white;
  flex-direction: column;
  justify-content: center;
  border: none;
  padding: 12px;
  gap: 12px;
  border-radius: 8px;
  cursor: pointer;
}

&::before {
  content: '';
  position: absolute;
  inset: 0;
  left: -4px;
  top: -1px;
  margin: auto;
  width: 128px;
  height: 48px;
  border-radius: 10px;
  background: linear-gradient(-45deg, #e81cff 0%, #40c9ff 100% );
  z-index: -10;
  pointer-events: none;
  transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

&::after {
  content: "";
  z-index: -1;
  position: absolute;
  inset: 0;
  background: linear-gradient(-45deg, #fc00ff 0%, #00dbde 100% );
  transform: translate3d(0, 0, 0) scale(0.95);
  filter: blur(20px);
}

&:hover::after {
  filter: blur(30px);
}

&:hover::before {
  transform: rotate(-180deg);
}

&:active::before {
  scale: 0.7;
}
`;

function SpaceItem({setIsPickerOpen, space, selectedSpace, setSelectedSpace, showName}: {
    setIsPickerOpen: any,
    space: SpaceType,
    selectedSpace: SpaceType | null,
    setSelectedSpace: any,
    showName: boolean
}): JSX.Element {
    const isSpaceSelected = selectedSpace !== null && selectedSpace.id === space.id;

    return (
        <SpaceItemWrapper 
            onClick={() => {
                    setSelectedSpace(space);
                    setIsPickerOpen(false);
            }} 
            style={{ opacity: isSpaceSelected ? 1 : 0.85, textDecoration: 'none', width: '100%' }}
        >
            <HStack aCenter maxWidth jStart>
                <SpaceImage space={space} size={30} />
                {
                    showName ? <SpaceName space={space} /> : <></>
                }
                <ArrowForwardIosRoundedIcon 
                    style={{ 
                        position: 'absolute', 
                        right: '10px', 
                        top: 'calc(50% - 14px/2)', 
                        fontSize: 14,
                        visibility: 'hidden'
                    }} 
                />
            </HStack>
        </SpaceItemWrapper>
    )
}


function SpaceItemPicker({space, onClick, forMiniCreator}: {
    space: SpaceType,
    onClick: any,
    forMiniCreator?: boolean
}): JSX.Element {
    return (
        <SpaceItemPickerWrapper 
            forMiniCreator={forMiniCreator}
            onClick={onClick}
            style={{ opacity: 1 , textDecoration: 'none' }}
        >
            <HStack aCenter maxWidth jStart>
                <SpaceImage space={space} size={30} />
                {
                    // <SpaceName space={space} />
                }
                <SpaceNameWrapper>
                    <strong>Post</strong>
                </SpaceNameWrapper>
            </HStack>

            <HSpacer width={10} />
            <SpaceDropdownChevron forMiniCreator={forMiniCreator || false} />
        </SpaceItemPickerWrapper>
    )
}

function SpaceItemPickerPlaceholder({onClick, forMiniCreator}: {
    onClick: any,
    forMiniCreator?: boolean
}): JSX.Element {
    return (
        <SpaceItemPickerWrapper 
            forMiniCreator={forMiniCreator}
            onClick={onClick}
            style={{ opacity: 1 , textDecoration: 'none' }}
        >
            <HStack aCenter maxWidth jStart>
                <SpaceImageWrapper
                    src={"https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/FCMImages%2Fdefault_spacey.webp?alt=media&token=e37eecbd-6b99-430c-b073-b57622ef8440"} 
                    id={"https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/FCMImages%2Fdefault_spacey.webp?alt=media&token=e37eecbd-6b99-430c-b073-b57622ef8440"}
                    alt={`Space Selector`}
                    size={30}
                />

                <div style={{ textDecoration: 'none' }}> 
                    <SpaceNameWrapper>
                        <strong>Post</strong>
                    </SpaceNameWrapper>
                </div>

                <HSpacer width={10} />
            </HStack>

            <SpaceDropdownChevron forMiniCreator={forMiniCreator || false} />
        </SpaceItemPickerWrapper>
    )
}

interface SpaceDropdownChevronProps {
    forMiniCreator: boolean;
  }
  
  const SpaceDropdownChevron: React.FC<SpaceDropdownChevronProps> = ({ forMiniCreator }) => {
    return (
      forMiniCreator ?
      <ExpandCircleDownRoundedIcon
          style={{
              fontWeight: 700, 
              fontSize: 20, 
              color: "#000",
          }} 
      /> :
      <KeyboardArrowDownIcon
          style={{
              fontWeight: 700, 
              fontSize: 20, 
              color: "#000",
          }} 
      />
    );
  };

interface SpaceImageWrapperType {
    size?: number | null | undefined;
}

const _SpaceImageWrapper = styled.img<SpaceImageWrapperType>;
  
const SpaceImageWrapper = _SpaceImageWrapper`
  object-fit: cover;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border-radius: 100%;
  background-color: white;
  margin-right: 5px;
`;

function replaceSpaceWithNewImage(img: string) {
    const OLD_DEFAULT_SPACE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/space_pictures%2Fdefault_space_picture.png?alt=media&token=86ebcd39-b198-47f9-a8ec-a7250e9a28ec";
    const DEFAULT_SPACE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/FCMImages%2Fdefault_spacey.webp?alt=media&token=e37eecbd-6b99-430c-b073-b57622ef8440";
  
    if (img === OLD_DEFAULT_SPACE_IMG) {
        return DEFAULT_SPACE_IMG
    } else {
        return img
    }
}


function SpaceImage({space, size}: {
  space: any;
  size: number;
}): JSX.Element {
  const [hasError, setHasError] = useState(false)

  return (
    space !== null ? 
    <SpaceImageWrapper
      src={hasError ? "https://cdn.hero.page/i/c0efe4-11dc-d546-1db-ab76dca7625-samoshasfallen-an-wallpaper-of-subtle-squigly-shapes-interlocki-1c96062e-aa83-46bc-afdf-455a8f03fd44-1.webp" : replaceSpaceWithNewImage(space.img)} 
      id={replaceSpaceWithNewImage(space.img)}
      onError={() => setHasError(true)}
      alt={`${space.name} on Hero`}
      size={size}
    /> : 
    <></>
  )
}

const SpaceNameWrapper = styled.span`
  font-size: 16px;
  font-weight: 500;
  padding-left: 10px;
  text-decoration: none;
  text-align: left;
  color: ${Colors.primaryDark};
  box-decoration-break: clone;
  float: left;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 300px;
    padding-top: 2px;
    padding-bottom: 2px;
`;

function SpaceName({space}: {
    space: SpaceType;
}): JSX.Element {
    return (
    space !== null ?
    <div style={{ textDecoration: 'none' }}> 
        <SpaceNameWrapper>
            {space.name}
        </SpaceNameWrapper>
    </div> : 
    <></>
    )
}


interface PostButtonProps {
    disabled: boolean;
    onClick?: () => void;
    dropdownContent: any;
}

const MainDiv = styled.div`
    position: relative;
    z-index: auto;

    &:hover {
        background: white;
        color: black;
    }
    
    @media (min-width: 768px) {
        padding: 2px 10px;
    }
  
  &:disabled {
    cursor: default;
    opacity: .24;
  }

  transition: all 0.3s ease;

  & .visible {
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    display: block !important;
  }
`;

const DropdownDiv = styled.div`
  position: absolute;
  display: none;
  margin-top: 0px;
  left: -240px;

  width: 400px;
  height: 500px;
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
  border-color: black;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  pointer-events: none;
  z-index: 100;

  @media (max-width: 401px) {
      width: calc(100vw - 8.3%);
      z-index: 100;
      left: calc(-100% + 16.6%);
  }
`;

const DropdownContent = styled.div`
    position: relative;
    width: 100%;
    overflow: hidden;
    height: 100%;
    background: white;
    align-items: center;
    border-radius: 15px;
    box-shadow: transparent 0 0 0 3px,rgba(18, 18, 18, .1) 0 6px 20px;
    box-sizing: border-box;
    color: #121212;

    display: inherit;
    font-family: Inter,sans-serif;
    font-size: 16px;
    font-weight: 700;
    justify-content: start;
    line-height: 1;
    outline: none;
    padding: 6px 12px;
    text-align: start;
    text-decoration: none;
    transition: all .2s,-webkit-box-shadow .2s;
    border: 0;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    align-items: center !important;
    width: auto !important;  // Add this line
    margin-top: 4px;
`;

export default PostSpaceSelector;