import React, { useState } from 'react';
import styled from 'styled-components';
import { ProfileType, SpaceStationType, SpaceTemplateType } from '../../Types/HeroTypes';
import { Typography, Tooltip, Divider } from '@mui/material';
import { Masonry } from '@mui/lab';
import VSpacer from '../../components/utils/VSpacer';
import Colors from '../../services/Colors';
import ViewInArRoundedIcon from '@mui/icons-material/ViewInArRounded';
import HStack from '../../components/utils/HStack';
import HSpacer from '../../components/utils/HSpacer';

import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CreateSpace from '../App/CreateSpace';

const brandPalette = ["#FF499E", "#D264B6", "#A480CF", "#779BE7", "#49B6FF"];


const CreateSpaceModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  overflow: "scroll",
  borderRadius: 1,
  zIndex: 1000000000000,
  padding: 3,
  minWidth: '350px'
};


const Bullet = styled.div<{ color: string }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => props.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  vertical-align: middle;
`;

const PreviewContainer = styled.div`
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    padding: 20px;
    width: 300px;
    // max-height: 400px;
    // padding: 10px;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
`;

const UseTemplateButton = styled.div`
& {
  align-items: center;
  background-color: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: .25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui,-apple-system,system-ui,"Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  width: 100%;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
}

&:hover,
&:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

&:hover {
  transform: translateY(-1px);
}

&:active {
  background-color: #F0F0F1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
`;

const TemplateHeader = styled.div`
    font-size: 14px;
    color: ${Colors.primaryDark};
    text-align: center;
`;

type TemplatePreviewProps = {
    templateData: SpaceTemplateType;
    fullWidth?: boolean;
    profile: ProfileType | null | undefined, 
    station: SpaceStationType[]
};

const TemplatePreview: React.FC<TemplatePreviewProps> = ({ profile, station, templateData, fullWidth = false }) => {
  const { space, lists } = templateData.template;
  const { type_of_space, usage_scenarios } = templateData.schema;


  const maxListsToShow = fullWidth ? 10 : 5; // You can adjust this number based on the space available

  const [isCreateSpaceOpen, setIsCreateSpaceOpen] = useState(false);

  return (
    <PreviewContainer style={fullWidth ? {width: "100%"}: {}}>
        <HStack aCenter maxWidth dynamic>
            <ViewInArRoundedIcon style={{fontSize: "16px"}} />
            <HSpacer width={10} />
            <TemplateHeader>
                <strong>{space}</strong>
            </TemplateHeader>
        </HStack>
        <VSpacer height={5} />
          {
            templateData.blog_link_info.post_id ? (
              <small>
              <a style={{color: "#000"}} href={`https://hero.page/blog/spaces/${templateData.blog_link_info.industry_slug}/${templateData.blog_link_info.title_slug}/${templateData.blog_link_info.post_id}`}>
                  {
                      usage_scenarios.slice(0, 2).join(", ")
                  }
              </a>
              </small>
          ) : (
            <small>
                  {
                      usage_scenarios.slice(0, 2).join(", ")
                  }
            </small>
          )
        }
        <VSpacer height={10} />
        <Masonry columns={1} spacing={0}>
            {lists.slice(0, maxListsToShow).map((list, index) => (
            <Tooltip
                key={index}
                title={`In this list, you can add items like ${list.items.join(', ')}`}
                placement="top"
                arrow
                enterDelay={500}
                leaveDelay={200}
            >
                <Typography
                variant="caption"
                style={{
                    padding: '10px',
                    borderRadius: '10px',
                    border: '1px solid rgba(0,0,0,0.06)',
                    cursor: 'pointer',
                    display: 'inline-flex',
                    alignItems: 'center',
                    marginBottom: 5
                }}
                >
                <Bullet color={brandPalette[index % brandPalette.length]} />
                {list.list_name}
                </Typography>
            </Tooltip>
            ))}
        </Masonry>
        {
          !fullWidth &&
          <UseTemplateButton onClick={() => setIsCreateSpaceOpen(true)}>Use Template</UseTemplateButton>
        }

        {
            <React.Suspense fallback={<></>}>
                <Modal
                    open={isCreateSpaceOpen}
                    onClose={() => setIsCreateSpaceOpen(false)}
                    aria-labelledby="modal-modal-template"
                    aria-describedby="modal-modal-template"
                >
                    <Box sx={CreateSpaceModalStyle}>
                        
                        <CreateSpace 
                            handleCreateSpaceClose={() => setIsCreateSpaceOpen(false)} 
                            profile={profile} 
                            station={station || []} 
                            isDefaultAppWorkflow={false}
                            setIsNewSpaceAppWorkflow={() => {}}
                            template={templateData}
                        />
                    </Box>
                </Modal>
            </React.Suspense>
        }
    </PreviewContainer>
  );
};

export default TemplatePreview;