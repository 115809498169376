import styled from 'styled-components';

interface VSpacerType {
  height?: number | null | undefined;
}

const _VSpacer = styled.div<VSpacerType>;

export default _VSpacer`
  ${props => props.height !== null ? `
        height: ${props.height}px;
    ` : ''
  }
`;
