import 'firebase/compat/firestore';
import slugify from 'react-slugify';
import { AIHuman, ArticleType, SpaceTemplateType, InvitationType, ItemFeedType, JobType, MessageGroupRefType, MessageGroupType, MissionType, OracleWish, PageType, ProfileRefType, ProfileSpaceRefType, ProfileType, QueuedItemType, SocialProfileType, SocialSpaceType, SpaceStationType, SpaceType, TaskType, UsernameType, UserFollowingType } from "../Types/HeroTypes";

const DEFAULT_SPACE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/FCMImages%2Fdefault_spacey.webp?alt=media&token=e37eecbd-6b99-430c-b073-b57622ef8440";

class Helper {
   PageDocToObject(ProfileDoc: any): PageType {
        const PageData: ProfileType | any = ProfileDoc.data();

        return {
            id: ProfileDoc.id,
            name: PageData.name,
            color: PageData.color,
            sortIndex: PageData.sortIndex,
            spaceID: PageData.spaceID,
            groupID: PageData.groupID,
            createdAt: PageData.createdAt,
            workflow_type: PageData.workflow_type || "items"
        }
   }

   ProfileSpaceDocToObject(ProfileSpaceDoc: any): ProfileSpaceRefType {
        const ProfileSpaceData: ProfileSpaceRefType | any = ProfileSpaceDoc.data();

        return {
            dateJoined: ProfileSpaceData.dateJoined,
            id: ProfileSpaceDoc.id
        }
    }

    ProfileRefDocToObject(ProfileRefDoc: any): ProfileRefType {
        const ProfileRefData: ProfileRefType | any = ProfileRefDoc.data();

        return {
            uid: ProfileRefDoc.id,
            hasAcceptedRequest: ProfileRefData.hasAcceptedRequest
        }
    }
    
    SpaceDocToObject(SpaceDoc: any): SpaceType {
        const SpaceData: SpaceType | any = SpaceDoc.data();

        return {
            id: SpaceDoc.id,
            name: SpaceData.name,
            img: SpaceData.img.trim() !== "" ? this.replaceSpaceWithNewImage(SpaceData.img) : DEFAULT_SPACE_IMG,
            thumbnail_img: SpaceData.thumbnail_img !== undefined && SpaceData.thumbnail_img !== null && SpaceData.thumbnail_img.trim() !== "" ? SpaceData.thumbnail_img : DEFAULT_SPACE_IMG,
            sortIndex: SpaceData.sortIndex,
            ownerUID: SpaceData.ownerUID,
            slug: SpaceData.slug,
            createdAt: SpaceData.createdAt,
            itemSummary: SpaceData.itemSummary !== undefined && SpaceData.itemSummary !== null ? SpaceData.itemSummary : [],
            messageGroupID: SpaceData.messageGroupID,
            muted_uids: SpaceData.muted_uids,
            shareable_link: SpaceData.shareable_link,
            originalSpaceIDTemplate: SpaceData.originalSpaceIDTemplate,
            dateLiked: SpaceData.dateLiked,
            members: SpaceData.members,
            bio: SpaceData.bio,
            config: (
                SpaceData.config !== null &&
                SpaceData.config !== undefined
            ) ? SpaceData.config : {
                searchByTitleOnly: false,
                allowSpaceClone: true
            }
        }
    }

    UsernameDocToObject(UsernameDoc: any): UsernameType {
        const UsernameData: UsernameType | any = UsernameDoc.data();

        return {
            uid: UsernameData.uid,
            dateRegistered: UsernameData.dateRegistered
        }
    }

    replaceWithNewImage(img: string) {
        const OLD_DEFAULT_PROFILE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/profile_pictures%2Fdefault.jpg?alt=media&token=3c6603e9-0292-4348-80c1-09db5558ca8e";
        const DEFAULT_PROFILE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/FCMImages%2Fdp.webp?alt=media&token=e6cc2ec8-b097-4166-9277-29a68a2afd74";

        if (img === OLD_DEFAULT_PROFILE_IMG) {
            return DEFAULT_PROFILE_IMG
        } else {
            return img
        }
    }

    replaceSpaceWithNewImage(img: string) {
        const OLD_DEFAULT_SPACE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/space_pictures%2Fdefault_space_picture.png?alt=media&token=86ebcd39-b198-47f9-a8ec-a7250e9a28ec";
        const DEFAULT_SPACE_IMG = "https://firebasestorage.googleapis.com/v0/b/focushero-1650416072840.appspot.com/o/FCMImages%2Fdefault_spacey.webp?alt=media&token=e37eecbd-6b99-430c-b073-b57622ef8440";

        if (img === OLD_DEFAULT_SPACE_IMG) {
            return DEFAULT_SPACE_IMG
        } else {
            return img
        }
    }

    ProfileDocToObject(ProfileDoc: any): ProfileType {
        const ProfileData: ProfileType | any = ProfileDoc.data();

        return {
            uid: ProfileDoc.id,
            username: ProfileData ? ProfileData.username : "",
            img: ProfileData ? this.replaceWithNewImage(ProfileData.img) || "" : "",
            device_id: ProfileData ? ProfileData.device_id || [] : [],
            isDeleted: ProfileData ? ProfileData.isDeleted || false : false,
            hasInitialisedTags: ProfileData ? ProfileData.hasInitialisedTags || false : false,
            isGridViewSelected: ProfileData ? ProfileData.isGridViewSelected || false : false,
            isAddButtonHintHidden: ProfileData ? ProfileData.isAddButtonHintHidden || false : false,
            isTaskTypeHintHidden: ProfileData ? ProfileData.isTaskTypeHintHidden || false : false,
            newSpacesBadgeNumber: ProfileData ? ProfileData.newSpacesBadgeNumber || 0 : 0,
            savedSpacesBadgeNumber: ProfileData ? ProfileData.savedSpacesBadgeNumber || 0 : 0,
            verification: ProfileData ? ProfileData.verification || "" : "",
            type: ProfileData ? ProfileData.type || "" : "",
            bio: ProfileData ? ProfileData.bio || "" : "",
            name: ProfileData ? ProfileData.name || "" : "",
            seo_title: ProfileData ? ProfileData.seo_title || "" : "",
            seo_description: ProfileData ? ProfileData.seo_description || "" : "",
            follower_count: ProfileData ? ProfileData.follower_count || 0 : 0, 
            numberOfItems: ProfileData ? ProfileData.numberOfItems || 0 : 0,
            numberOfSpaces: ProfileData ? ProfileData.numberOfSpaces || 0 : 0,
            numberOfContributions: ProfileData ? ProfileData.numberOfContributions || 0 : 0,
            spacesContributedTo: ProfileData ? ProfileData.spacesContributedTo : [] || [],
            moreSpaces: ProfileData ? ProfileData.moreSpaces || false : false,
            score: ProfileData ? ProfileData.score || 0 : 0,
            ticketType: ProfileData ? ProfileData.ticketType || undefined : undefined,
        }
    }

    SpaceStationDocToObject(StationDoc: any): SpaceStationType {
        const  SpaceStationData:  SpaceStationType | any = StationDoc.data();

        return {
            id: StationDoc.id,
            isWidget: SpaceStationData.isWidget || false,
            sortIndex: SpaceStationData.sortIndex
        }
    }

    SocialSpaceDocToObject(SocialSpaceDoc: any): SocialSpaceType {
        const  SocialSpaceData:  SocialSpaceType | any = SocialSpaceDoc.data();

        return {
            space_id: SocialSpaceDoc.id,
            dateAddedToProfile: SocialSpaceData.dateAddedToProfile,
            slug: SocialSpaceData.slug
        }
    }

    SocialProfileDocToObject(SocialProfileDoc: any): SocialProfileType {
        const  SocialSpaceData:  SocialProfileType | any = SocialProfileDoc.data();

        return {
            id: SocialSpaceData.id,
            url: SocialSpaceData.url
        }
    }


    InvitationDocToObject(InvitationDoc: any): InvitationType {
        const InvitationData:  SpaceStationType | any = InvitationDoc.data();

        return {
            id: InvitationDoc.id,
            inviter_uid: InvitationData.inviter_uid,
            inviter_username: InvitationData.inviter_username,
            img: InvitationData.img,
            type: InvitationData.type,
            id_of_space_invited: InvitationData.id_of_space_invited,
            name_of_space_invited: InvitationData.name_of_space_invited,
            time_of_invitation: InvitationData.time_of_invitation
        }
    }

    UserFollowingDocToObject(UserFollowingDoc: any): UserFollowingType {
        const UserFollowingData:  UserFollowingType | any = UserFollowingDoc.data();

        return {
            uid: UserFollowingData.uid,
            username: UserFollowingData.username,
            img: UserFollowingData.img,
            dateFollowed: UserFollowingData.dateFollowed
        }
    }

    MessageGroupRefToObject(MessageGroupRefDoc: any): MessageGroupRefType {
        const MessageGroupRefData:  MessageGroupRefType | any = MessageGroupRefDoc.data() as MessageGroupRefType;

        return {
            messageGroupID: MessageGroupRefDoc.id,
            dateAddedToMessageGroup: MessageGroupRefData.dateAddedToMessageGroup
        }
    }

    MessageGroupToObject(MessageGroupDoc: any): MessageGroupType {
        const MessageGroupData:  MessageGroupType | any = MessageGroupDoc.data();

        return {
            id: MessageGroupDoc.id,
            spaceID: MessageGroupData.spaceID,
            name: MessageGroupData.name,
            img: MessageGroupData.img,
            newestMessageTime: MessageGroupData.newestMessageTime,
            newestMessage: MessageGroupData.newestMessage,
            createdAt: MessageGroupData.createdAt,
            ownerUID: MessageGroupData.ownerUID,
            members: MessageGroupData.members,
            muted_uids: MessageGroupData.muted_uids,
            active_user_uids: MessageGroupData.active_user_uids,
            profiles_marked_as_read: MessageGroupData.profiles_marked_as_read
        }
    }

    TaskDocToObject(TaskDoc: any): TaskType {
        const TaskData: TaskType | any = TaskDoc.data();

        return {
            id: TaskDoc.id,
            title: TaskData.title,
            img: TaskData.img,
            url: TaskData.url,
            note: Array.isArray(TaskData.note) ? TaskData.note.join(' ') : TaskData.note,
            pageID: TaskData.pageID,
            spaceID: TaskData.spaceID,
            itemID: TaskData.itemID || "",
            sortIndex: TaskData.sortIndex,
            owner_uid: TaskData.owner_uid,
            is_archived: TaskData.is_archived,
            createdAt: TaskData.createdAt,
            fileURL: TaskData ? TaskData.fileURL || "" : "",
            fileName: TaskData ? TaskData.fileName || "" : "",
            itemRepliedTo: TaskData ? TaskData.itemRepliedTo || {} : {},
            replyRepliedTo: TaskData ? TaskData.replyRepliedTo || {} : {},
            space: TaskData ? TaskData.space || {} : {},
            isReply: TaskData ? TaskData.isReply || false : false,
            is_image_full_width: TaskData ? TaskData.is_image_full_width || false : false,
            urlMetadata: TaskData ? TaskData.urlMetadata || null : null,
            submitted_by_anon: TaskData ? TaskData.submitted_by_anon || false : false,
            submitted_by: TaskData ? TaskData.submitted_by || {} : {},
            generated_by: TaskData ? TaskData.generated_by || {} : {},
            image_generated_on_hero: TaskData ? TaskData.image_generated_on_hero || false : false,
            send_approval_email: TaskData ? TaskData.send_approval_email || false : false,
            ticketType: TaskData ? TaskData.ticketType || "" : "",
            addedToPopular: TaskData ? TaskData.addedToPopular || false : false,
            isMediaHidden: TaskData ? TaskData.isMediaHidden || false : false,
            isAIPrompt: TaskData ? TaskData.isAIPrompt || false : false,
            isNSFW: TaskData ? TaskData.isNSFW || false : false,
        }
    }

    QueuedItemDocToObject(QueuedItemDoc: any): QueuedItemType {
        const QueuedItemData: QueuedItemType | any = QueuedItemDoc.data();

        return {
            id: QueuedItemDoc.id,
            spaceIDToDownload: QueuedItemData.spaceIDToDownload,
            createdAt: QueuedItemData.createdAt,
            hasFinishedDownloading: QueuedItemData.hasFinishedDownloading,
            generatedSortIndexforStation: QueuedItemData.generatedSortIndexforStation    
        }
    }

    OracleWishDocToObject(OracleWishDoc: any): OracleWish {
        const OracleWishData: OracleWish | any = OracleWishDoc.data();

        return {
            id: OracleWishDoc.id,
            createdAt: OracleWishData.createdAt,
            prompt: OracleWishData.prompt,
            prompt_term: OracleWishData.prompt_term,
            query: OracleWishData.query,
            isCasting: OracleWishData.isCasting || false,
            isReady: OracleWishData.isReady || false,
            scheduledFor: OracleWishData.scheduledFor || null
        }
    }

    HumanDocToObject(HumanDoc: any): AIHuman {
        const HumanData: AIHuman | any = HumanDoc.data();

        return {
            id: HumanDoc.id,
            country: HumanData.country || "",
            createdAt: HumanData.createdAt || "",
            image: HumanData.image || "",
            interest: HumanData.interest || "",
            name: HumanData.name || "",
            username: HumanData.username || "",
            isCreated: HumanData.isCreated || false,
            isInvalid: HumanData.isInvalid || false,
            isCasting: HumanData.isCasting || false,
            isAPIActive: HumanData.isAPIActive || false
        }
    }

    JobDocToObject(JobDoc: any): JobType {
        const JobData: JobType | any = JobDoc.data();

        return {
            id: JobDoc.id,
            data: JobData.data,
            demographic: JobData.demographic,
            interest: JobData.interest,
            isCasting: JobData.isCasting || false,
            isComplete: JobData.isComplete || false,
            roster: JobData.roster,
            isIndexed: JobData.isIndexed || false,
        }
    }

    ArticleDocToObject(ArticleDoc: any): ArticleType {
        const ArticleData: ArticleType | any = ArticleDoc.data();

        return {
            id: ArticleDoc.id,
            prefix: ArticleData.prefix || "",
            response: ArticleData.response || "",
            elaboration: ArticleData.elaboration || "",
            topic: ArticleData.topic,
            isCasting: ArticleData.isCasting || false,
            origin: ArticleData.origin || ""
        }
    }

    MissionDocToObject(MissionDoc: any): MissionType {
        const MissionData: MissionType | any = MissionDoc.data();

        return {
            id: MissionDoc.id,
            space: MissionData.space,
            owner: MissionData.owner,
            isRealized: MissionData.isRealized || false
        }
    }

    ItemFeedTypeToTask(ItemFeedObject: ItemFeedType, fullSizeIMG: boolean = false): TaskType {

        const extraParams = ItemFeedObject.generated_by && ItemFeedObject.image_generated_on_hero ?
        {
            generated_by: ItemFeedObject.generated_by,
            image_generated_on_hero: ItemFeedObject.image_generated_on_hero,
            isNSFW: ItemFeedObject.isNSFW || false,
        } : {};

        return {
            id: ItemFeedObject.id,
            title: ItemFeedObject.title,
            img: ItemFeedObject.img,
            url: ItemFeedObject.url,
            note: ItemFeedObject.note,
            isAIPrompt: ItemFeedObject.isAIPrompt || false,
            sortIndex: 0,
            owner_uid: ItemFeedObject.owner_uid,
            createdAt: ItemFeedObject.createdAt,
            urlMetadata: ItemFeedObject.urlMetadata,
            submitted_by: ItemFeedObject.submitted_by,
            is_image_full_width: fullSizeIMG,
            ...extraParams
        }
    }

    getItemSlug(item: TaskType, max: number = 5): string {
        const extractMaxSixWords = (text: string): string => {
            const words = text.split(' ').slice(0, max);
            return words.join(' ');
        };
    
        const candidates = [
            item.title,
            item.note,
            item.urlMetadata?.title,
            item.urlMetadata?.description,
        ];
    
        for (const candidate of candidates) {
            if (candidate && candidate.trim().length > 0) {
                const slug = slugify(extractMaxSixWords(candidate)) || 'post';
                return `${slug}-${item.id}`;
            }
        }
    
        return '';
    }

    getItemIdFromSlug(slug: any) {
        // Assume the ID is the last part of the slug after the last '-'
        const potentialId = slug.split('-').pop();
    
        // Validate the ID - this is a simple example that checks if the ID is a non-empty string.
        // You may want to replace this with a more comprehensive check, depending on the format of your IDs.
        if (typeof potentialId === 'string' && potentialId.trim().length > 0) {
            return potentialId;
        }
    
        // If validation fails, return null
        return null;
    }

    spaceTemplateDocToObject(spaceTemplateDoc: any): SpaceTemplateType | null {
        const spaceTemplateData: SpaceTemplateType | any = spaceTemplateDoc.data();
    
        // Validate the data according to your criteria (e.g., required fields, data types, etc.)
        if (
            spaceTemplateData.template &&
            spaceTemplateData.schema &&
            typeof spaceTemplateData.createdAt === 'number' &&
            typeof spaceTemplateData.id === 'string' &&
            spaceTemplateData.blog_link_info &&
            typeof spaceTemplateData.image === 'string'
        ) {
            return {
                ...spaceTemplateData,
                id: spaceTemplateDoc.id // if the ID should be obtained from the document reference
            };
        } else {
            console.error('Invalid SpaceTemplate data:', spaceTemplateData);
            return null; // or throw an error, or handle the invalid data as needed
        }
    }
    
}

export default new Helper();
